import React, { ReactNode } from "react";
import { Spin, Space } from "antd";
import styles from "./loading.module.css";

interface FullScreenLoadingInterface {
  children: ReactNode,
  className?: string, }


interface LoadingInterface {
  className?: string }


export const Loading = ({ className }: LoadingInterface) => {
  return (
    <div data-testid="loading" className={className}>
      <Space size="large">
        <Spin size="large" />
      </Space>
    </div>);

};

export const FullScreenLoading = ({ children, className }: FullScreenLoadingInterface) => {
  return (
    <div className={styles.fullScreenLoadContainer}>
      <div className={styles.content}>
        <Loading className={className} />
        {children}
      </div>
    </div>);

};