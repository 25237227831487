import React from "react";
import { blackOrWhite, colorRangeManager, generateKey, hexToRGB } from "../../utils/tools";
import styles from "./categories.module.css";

interface Categories {
  categories: string[] }

interface CategoryItemProps {
  categoryName: string,
  uniqKey: string, }


export const ColorCategories = ({ categories }: Categories) => {
  return (
    <div className={styles.categoriesContainer}>
      {categories.map((item, index) =>
      <CategoryItem categoryName={item} uniqKey={generateKey("categoryItem")} key={index} />
      )}
    </div>);

};

const CategoryItem = ({ categoryName, uniqKey }: CategoryItemProps) => {
  const categoryColor = colorRangeManager.getCategoryColor(categoryName);
  const rgbColor = hexToRGB(categoryColor);

  return (
    <div
      className={styles.categoryItem}
      key={uniqKey}
      style={{
        background: categoryColor,
        color: blackOrWhite(...rgbColor)
      }}>

      {categoryName}
    </div>);

};