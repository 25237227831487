import React from "react";
import cn from "classnames";
import ReactDOM from "react-dom/client";
import { addDataToMap } from "kepler.gl/actions";
import styles from "./keplergl.module.css";
import TimeLineArrow from "../../images/time-line-arrow.svg";
import { KeplerCustomTooltip } from "./components/keplerCustomTooltip";
import { Event as DataEvent } from "../../interfaces/events";

export const SUDAN_BORDER_LINE_LAYER_INDEX = 0;
export enum LayerModes {
  Heatmap,
  Geojson,
}
interface DatasetInterface {
  info: {
    label: string;
    id: string;
  };
  data: any;
}
interface TimelineControlButton {
  add: () => void;
  remove: () => void;
}

export const VERIFIED_DATA_ID = 0;
export const LATITUDE_ID = 1;
export const LONGITUDE_ID = 2;
export const TIME_DATA_ID = 3;

export const EDITOR_MODE_VERIFIED_DATA_ID = 4;
export const EDITOR_MODE_LATITUDE_ID = 5;
export const EDITOR_MODE_LONGITUDE_ID = 6;
export const EDITOR_MODE_TIME_DATA_ID = 7;

export const EVENTS_DATA_LAYER_INDEX = 0;
export const EVENTS_DATA_LAYER_NAME = "Events data";
export const EVENTS_DATA_LAYER_ID = "events_data";

export const EDITOR_MODE_DATA_LAYER_INDEX = 1;
export const EDITOR_MODE_DATA_LAYER_NAME = "Editor Mode data";
export const EDITOR_MODE_DATA_LAYER_ID = "editor_mode_data";

export class DefaultKeplerFilters {
  private static filters: Record<string, number> = {
    VERIFIED_DATA_ID: 0,
    LATITUDE_ID: 1,
    LONGITUDE_ID: 2,
    TIME_DATA_ID: 3,

    EDITOR_MODE_VERIFIED_DATA_ID: 4,
    EDITOR_MODE_LATITUDE_ID: 5,
    EDITOR_MODE_LONGITUDE_ID: 6,
    EDITOR_MODE_TIME_DATA_ID: 7,
  };
  public static publicFilters: Record<string, number> = {};
  private static currentCountRowsData: number = 0;

  private static getFiltersConfig() {
    const currentFilters =
      this.currentCountRowsData > 2
        ? this.filters
        : {
            LATITUDE_ID: 1,
            LONGITUDE_ID: 2,
            EDITOR_MODE_LATITUDE_ID: 5,
            EDITOR_MODE_LONGITUDE_ID: 6,
          };
    const config: any[] = [];

    this.publicFilters = {};
    let counter = 0;
    const allFilters = eventsDataFilters.concat(editorModeDataFilters);
    for (let key in currentFilters) {
      config.push({ ...allFilters[currentFilters[key]], id: counter });
      this.publicFilters[key] = counter;
      counter += 1;
    }
    return config;
  }

  public static genConfig(countRows: number) {
    this.currentCountRowsData = countRows;
    const filtersConfig = this.getFiltersConfig();
    return {
      visState: {
        filters: filtersConfig,
      },
    };
  }
}

const eventsDataFilters = [
  {
    id: VERIFIED_DATA_ID,
    dataId: EVENTS_DATA_LAYER_ID,
    name: "Timeline of verified data",
    type: "timeRange",
    enlarged: true,
    speed: 0.5,
  },
  {
    id: LATITUDE_ID,
    dataId: EVENTS_DATA_LAYER_ID,
    name: "lat",
    type: "float",
  },
  {
    id: LONGITUDE_ID,
    dataId: EVENTS_DATA_LAYER_ID,
    name: "long",
    type: "float",
  },
  {
    id: TIME_DATA_ID,
    dataId: EVENTS_DATA_LAYER_ID,
    name: "timeNum",
    type: "float",
  },
];

const editorModeDataFilters = [
  {
    id: EDITOR_MODE_VERIFIED_DATA_ID,
    dataId: EDITOR_MODE_DATA_LAYER_ID,
    name: "Timeline of verified data",
    type: "timeRange",
    enlarged: true,
    speed: 0.5,
  },
  {
    id: EDITOR_MODE_LATITUDE_ID,
    dataId: EDITOR_MODE_DATA_LAYER_ID,
    name: "lat",
    type: "float",
  },
  {
    id: EDITOR_MODE_LONGITUDE_ID,
    dataId: EDITOR_MODE_DATA_LAYER_ID,
    name: "long",
    type: "float",
  },
  {
    id: EDITOR_MODE_TIME_DATA_ID,
    dataId: EDITOR_MODE_DATA_LAYER_ID,
    name: "timeNum",
    type: "float",
  },
];

export const staticDatasetsConfig: Record<number, DatasetInterface> = {};

const datasetsConfig: Record<number, any> = {};
datasetsConfig[EVENTS_DATA_LAYER_INDEX] = {
  filters: eventsDataFilters,
  name: EVENTS_DATA_LAYER_NAME,
  id: EVENTS_DATA_LAYER_ID,
};
datasetsConfig[EDITOR_MODE_DATA_LAYER_INDEX] = {
  filters: editorModeDataFilters,
  name: EDITOR_MODE_DATA_LAYER_NAME,
  id: EDITOR_MODE_DATA_LAYER_ID,
};

const tooltipBlock = document.createElement("div");
const tooltipRoot = ReactDOM.createRoot(tooltipBlock);

export const addDataToKeplerMapActionCreator = (data: any, center: boolean, config: any) => {
  let datasets: any[] = [];

  for (let key in datasetsConfig) {
    datasets.push({
      info: {
        label: datasetsConfig[key].name,
        id: datasetsConfig[key].id,
      },
      data: data,
    });
  }

  attachStaticDatasets(datasets);

  return addDataToMap({
    datasets: datasets,
    option: {
      centerMap: center,
      readOnly: false,
    },
    config: config,
  });
};

const attachStaticDatasets = (data: DatasetInterface[]) => {
  for (let key in staticDatasetsConfig) {
    data.push(staticDatasetsConfig[key]);
  }
};

const removeCustomToolTip = (obj: Element | null) => {
  if (!obj) {
    return;
  }

  obj.remove();
};

export const openCustomToolTip = (() => {
  let lastHoverObject: any;

  return (hoverObject: DataEvent | undefined) => {
    const popover = document.querySelector(".map-popover") as HTMLDivElement;
    const lastBlock = popover ? popover.querySelector(`.${styles.tooltip}`) : null;

    if (!hoverObject) {
      lastHoverObject = hoverObject;
      removeCustomToolTip(lastBlock);
      return;
    }

    if (lastHoverObject) {
      if (lastHoverObject.properties.uniqueIndex === hoverObject.properties.uniqueIndex) {
        return;
      } else {
        removeCustomToolTip(lastBlock);
      }
    }

    if (!popover) {
      return;
    }
    lastHoverObject = hoverObject;

    tooltipRoot.render(<KeplerCustomTooltip object={hoverObject} />);

    tooltipBlock.className = styles.tooltip;
    popover.append(tooltipBlock);
  };
})();

export const HTMLTimelineControlButton = (
  target: HTMLElement,
  open: boolean,
  changeCallback: (open: boolean) => void,
  controlButtonsBlock: HTMLDivElement | null
): TimelineControlButton => {
  let btnStateOpen = open;

  target.style.position = "relative";
  target.classList.add(styles.timelineBlock);

  const btn = document.createElement("button");
  btn.className = styles.timelineControlButton;

  const btnImage = document.createElement("img");
  btnImage.src = TimeLineArrow;
  btn.append(btnImage);

  const setStyle = () => {
    const { height } = target.getBoundingClientRect();

    if (controlButtonsBlock) {
      let newBottom = height - 10;
      newBottom = newBottom < 35 ? 35 : newBottom;

      controlButtonsBlock.style.bottom = btnStateOpen ? `${newBottom}px` : "35px";
    }

    if (btnStateOpen) {
      target.classList.remove(styles.timelineBlockClose);
    } else {
      target.classList.add(styles.timelineBlockClose);
    }

    btnImage.className = cn(styles.timelineControlImage, !btnStateOpen && styles.timelineControlImageClose);
  };

  const clickHandler = () => {
    btnStateOpen = !btnStateOpen;
    changeCallback(btnStateOpen);
    setStyle();
  };
  btn.addEventListener("click", clickHandler);
  setStyle();
  setTimeout(setStyle, 300);

  return {
    add: () => {
      target.append(btn);
    },
    remove: () => {
      target.classList.remove(styles.timelineBlockClose);
      target.style.position = "";
      target.classList.remove(styles.timelineBlock);

      btn.removeEventListener("click", clickHandler);
      target.removeChild(btn);
    },
  };
};
