import i18n from 'i18next';
import React from "react";
import { Drawer, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import styles from "./adaptiveModal.module.css";

interface AdaptiveModalProps {
  open: boolean,
  close: () => void,
  children: React.ReactNode, }

export const AdaptiveModal = ({ open, children, close }: AdaptiveModalProps) => {
  return (
    <>
      <Drawer
        className={styles.mobileModal}
        title={<span className={styles.mobileModalBackButton}>{i18n.t("BACK")}</span>}
        placement="left"
        open={open}
        destroyOnClose={true}
        width="100%"
        closeIcon={<div data-testid="close-drawer-btn"><ArrowLeftOutlined onClick={close} /></div>}
        bodyStyle={{ padding: 0 }}
        drawerStyle={{ background: i18n.t("VAR_GREY"), padding: 0 }}
        headerStyle={{ background: i18n.t("VAR_GREY"), borderBottom: i18n.t("PX_SOLID_RGBA") }}>

        {children}
      </Drawer>
      <div className={styles.desktopModal}>
        <Modal
          getContainer={false}
          title={null}
          closeIcon={null}
          onCancel={close}
          bodyStyle={{ padding: 0 }}
          open={open}
          footer={null}>

          {children}
        </Modal>
      </div>
    </>);

};