export const categoryList = [
"Airstrikes",
"Arrest, Detention or Abduction",
"Bombing and explosion",
"Capture of opposing combatants",
"Civilian death",
"Civilian injury",
"Civilian property damage",
"Civilian property looting or occupation",
"Commercial facilities damage",
"Displacement of civilians",
"Educational facilities damage",
"Fire",
"Firing positions",
"Food supplies and agriculture damage",
"Forced recruitment or conscription of civilians",
"Forms of equipment or munitions",
"Government buildings damage",
"Gunfire",
"Harm to combatants",
"Humanitarian aid resources seizure or damage",
"Humiliation of civilians",
"Incitement and hate-speech",
"Infrastructure damage",
"Interrogation or torture of civilians",
"Locations and movements",
"Mass graves and burial sites",
"Medical and health facilities damage",
"Military facilities and assets damage",
"Other",
"Physical violence",
"Religious Buildings",
"Roadblocks or restrictions on physical movement",
"Sexual and gender-based violence of civilians",
"Unexploded ordinance",
"Water infrastructure damage"];


export const Actor = [
"Armed self defense groups",
"External forces",
"RSF",
"SAF",
"Other",
"Unknown"];


export const Source = [
"CIR Verified",
"Partner Network",
"Satellite",
"ACLED"];