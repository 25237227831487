import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import english from './strings/english';
import arabic from './strings/arabic';

// the translations
const resources = {
  en: {
    translation: english
  },
  ar: {
    translation: arabic
  }
};

i18n.
use(detector).
use(initReactI18next).
init({
  resources,
  interpolation: {
    escapeValue: false // react already safe from xss
  },
  fallbackLng: "en" // use en if detected lng is not available
});

export default i18n;