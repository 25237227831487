import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import cn from "classnames";
import { CATEGORIES_COLOR_SETTING } from "../../../utils/tools";
import LegendIcon from "../../../images/legend-icon.svg";
import { ActiveListItem } from "../../../components/activeList";
import MinimizeIcon from "../../../images/minimize-icon.svg";
import styles from "../keplergl.module.css";
import i18n from 'i18next';

interface CategoriesInterface {
  color: string;
  name: string;
  categories: string[];
}

interface DatabaseCategories {
  name: string;
  categories: CategoriesInterface[];
}

interface CategoriesListProps {
  categories: string[];
}

const groupingCategoriesByColor = (categories: Record<string, string>): CategoriesInterface[] => {
  let group: Record<string, string[]> = {};

  for (let key in categories) {
    const color = categories[key];
    if (!group[color]) {
      group[color] = [key];
      continue;
    }

    group[color].push(key);
  }

  let result: CategoriesInterface[] = [];

  for (let color in group) {
    const categoryObj: CategoriesInterface = {
      color: color,
      name: group[color].join(", "),
      categories: group[color],
    };

    result.push(categoryObj);
  }

  return result;
};

export const LegendPopup: React.FC<ActiveListItem> = ({ onActive, onInActive, active }: ActiveListItem) => {
  const [databases, setDatabases] = useState<DatabaseCategories[]>([]);
  const [popupOpen, setPopupOpen] = useState<boolean>(active);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    setPopupOpen(active);
  }, [active]);

  const tooltipOpenChange = (open: boolean) => {
    if (popupOpen) {
      setTooltipOpen(false);
      return;
    }

    setTooltipOpen(open);
  };

  const openPopup = () => {
    onActive();
    setTooltipOpen(false);
    setPopupOpen(true);
  };

  const closePopup = () => {
    onInActive();
    setTooltipOpen(true);
    setPopupOpen(false);
  };

  useEffect(() => {
    setDatabases([
      {
        name: i18n.t("EVENTS"),
        categories: groupingCategoriesByColor(CATEGORIES_COLOR_SETTING),
      },
    ]);
  }, []);

  return (
    <Tooltip placement="left" open={tooltipOpen} onOpenChange={tooltipOpenChange} title={i18n.t("LEGEND")}>
      <div className={styles.expandContainer}>
        <img
          className={cn(styles.expandBtn, popupOpen && styles.hideExpandBtn)}
          onClick={openPopup}
          src={LegendIcon}
          alt="Legend"
        />
        {popupOpen && (
          <div className={styles.expandPopupContent}>
            <button onClick={closePopup} className={styles.minimizeBtn}>
              <img src={MinimizeIcon} alt="Minimize info popup" />
            </button>
            <div className={styles.databaseContainer}>
              {databases.map((item) => {
                return (
                  <div className={styles.legendDBContent}>
                    <span className={styles.legendPopupDBName}>{item.name}</span>

                    <div>
                      {item.categories.map((groupItem) => {
                        return (
                          <div className={styles.legendPopupDBCategory}>
                            <span className={styles.DBCategoryColor} style={{ background: groupItem.color }} />

                            <Tooltip title={<CategoriesList categories={groupItem.categories} />}>
                              <span className={styles.DBCategoryName}>{groupItem.name}</span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

const CategoriesList = ({ categories }: CategoriesListProps) => {
  return (
    <div className={styles.popUpCategoriesList}>
      {categories.map((item) => {
        return <span className={styles.DBCategoryName}>{item}</span>;
      })}
    </div>
  );
};
