import { ActionTypes } from "kepler.gl/actions";
import { DefaultKeplerFilters } from "../../keplerMapUtils";

type DateRange = [Date, Date] | null;
type TimeRange = [number, number];
export interface BoundingRect {
  lat: [number, number],
  long: [number, number], }

type KeplerFilterProp = "value" | "name" | "dataId" | "layerId";

const setKeplerFilterActionCreator = <T>(id: number, value: T, prop: KeplerFilterProp = "value") => {
  return {
    type: ActionTypes.SET_FILTER,
    payload: {
      idx: id,
      meta: { _id_: "map" },
      prop: prop,
      type: ActionTypes.SET_FILTER,
      value: value,
      valueIndex: undefined
    },
    meta: {
      _addr_: "@@KG_MAP",
      _forward_: "@redux-forward/FORWARD"
    }
  };
};

const setKeplerTimelineValueActionCreator = <T>(id: number, value: T) => {
  return {
    type: ActionTypes.SET_FILTER_ANIMATION_TIME,
    payload: {
      idx: id,
      type: ActionTypes.SET_FILTER_ANIMATION_TIME,
      meta: { _id_: "map" },
      valueIndex: undefined,
      prop: "value",
      value: value
    },
    meta: {
      _addr_: "@@KG_MAP",
      _forward_: "@redux-forward/FORWARD"
    }
  };
};

const removeFilterActionCreator = (filterId: number) => {
  return {
    type: ActionTypes.REMOVE_FILTER,
    payload: { type: ActionTypes.REMOVE_FILTER, idx: filterId, meta: { _id_: "map" } },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };
};

const enlargeFilter = (id: number) => {
  return {
    type: ActionTypes.ENLARGE_FILTER,
    payload: { type: ActionTypes.ENLARGE_FILTER, idx: id, meta: { _id_: "map" } },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };
};

const dateRangeToTimeRange = (dateRange: DateRange): TimeRange | null => {
  if (!dateRange) {
    return null;
  }
  return [dateRange[0].getTime(), dateRange[1].getTime()];
};

const setKeplerOnlyMapFrameEvents = (boundingRect: BoundingRect) => {
  const latAction = setKeplerFilterActionCreator < [number, number] > (
  DefaultKeplerFilters.publicFilters.LATITUDE_ID,
  boundingRect.lat);

  const longAction = setKeplerFilterActionCreator < [number, number] > (
  DefaultKeplerFilters.publicFilters.LONGITUDE_ID,
  boundingRect.long);


  return [latAction, longAction];
};

const setKeplerMapAnimationDataRange = (dateRange: DateRange) => {
  const timeRange = dateRangeToTimeRange(dateRange);
  if (!timeRange) {
    return;
  }

  return setKeplerTimelineValueActionCreator(DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID, timeRange);
};

const setKeplerDateRange = (timeRange: [number, number] | null) => {
  if (!timeRange) {
    return;
  }

  return [
  setKeplerFilterActionCreator < [number, number] > (
  DefaultKeplerFilters.publicFilters.EDITOR_MODE_TIME_DATA_ID,
  timeRange),

  setKeplerFilterActionCreator < [number, number] > (DefaultKeplerFilters.publicFilters.TIME_DATA_ID, timeRange)];

};

const enlargeTimelineFilter = (filterId: number) => {
  return enlargeFilter(filterId);
};

const addFilter = (dataId: string) => {
  return {
    type: ActionTypes.ADD_FILTER,
    payload: { type: ActionTypes.ADD_FILTER, dataId: dataId, meta: { _id_: "map" } },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };
};

const setFilterName = (id: number, value: string) => {
  return setKeplerFilterActionCreator(id, value, "name");
};

const setFilterDataId = (id: number, dataId: string) => {
  return setKeplerFilterActionCreator(id, dataId, "dataId");
};

const setFilterValue = (id: number, value: any, prop: KeplerFilterProp) => {
  return setKeplerFilterActionCreator(id, value, prop);
};

const updateFilterAnimationSpeed = (id: number, speed: number) => {
  return {
    type: ActionTypes.UPDATE_FILTER_ANIMATION_SPEED,
    payload: { type: ActionTypes.UPDATE_FILTER_ANIMATION_SPEED, idx: id, speed: speed, meta: { _id_: "map" } },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };
};

const setPolygonFilterLayerAction = (feature: any, layer: any) => {
  return {
    type: ActionTypes.SET_POLYGON_FILTER_LAYER,
    payload: {
      type: ActionTypes.SET_POLYGON_FILTER_LAYER,
      feature: feature,
      meta: { _id_: "map" },
      layer: layer
    },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };
};

export const KeplerMapFilters = {
  setKeplerOnlyMapFrameEvents,
  setKeplerMapAnimationDataRange,
  setKeplerDateRange,
  enlargeTimelineFilter,
  addFilter,
  setFilterName,
  setFilterValue,
  removeFilterActionCreator,
  setFilterDataId,
  updateFilterAnimationSpeed,
  setPolygonFilterLayerAction
};