import React, { useEffect, useState, useRef, useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import KeplerGl from "kepler.gl";
import AutoSizer from "react-virtualized-auto-sizer";
import { DEFAULT_MAP_STATE, RootState } from "../../store/index";
import { KeplerMapFilters, BoundingRect } from "./packages/keplerFilter/keplerFilter";
import { MapController } from "./components/mapStyleChanger";
import { EditorModePopup } from "./components/edtiorMode";
import { InfoPopup } from "./components/infoPopup";
import { keplerExtension } from "../../constants/actionTypes";
import { colorRangeManager, compareArrays, getKeplerSelector, HEAT_MAP_COLOR_RANGE } from "../../utils/tools";
import {
  enableHeatMode,
  enableGeojsonMode,
  enablePointMode,
} from "./reducerExtensions/keplerReduxExtension";
import {
  HTMLTimelineControlButton,
  openCustomToolTip,
  LayerModes,
  addDataToKeplerMapActionCreator,
  DefaultKeplerFilters,
  EDITOR_MODE_DATA_LAYER_INDEX,
} from "./keplerMapUtils";
import { rectIsValid } from "../../utils/packages/searchEngine/searchEngine";
import { TimelineManager } from "./timelineManager";
import styles from "./keplergl.module.css";
import { ActiveList } from "../../components/activeList";
import { LegendPopup } from "./components/legendPopup";
import { ExportPdf } from "./components/exportPdf";
import { getRTLTextPluginStatus, setRTLTextPlugin } from "mapbox-gl";

type Func = () => void;

const MAX_ZOOM = 24;
const MIN_ZOOM = DEFAULT_MAP_STATE.zoom;
const ZOOM_STEP = 0.5;

export const Kepler = () => {
  const controlButtonsBlock = useRef<HTMLDivElement>(null);
  const [layerMode, setLayerMode] = useState<LayerModes>(LayerModes.Geojson);
  const [mapBoxRef, setMapBoxRef] = useState<any>();
  const [currentMapUrl, setCurrentMapUrl] = useState<string>();
  const [oldFilteredIndexes, setOldFilteredIndexes] = useState<number[]>([]);
  const [timelineOpen, setTimeLineOpen] = useState<boolean>(true);
  const { searchEngine, filteredIndexes } = useSelector((state: RootState) => state.searchEngine);
  const { currentMap } = useSelector((state: RootState) => state.keplerMapState);
  const editorModeSelector = useSelector((state: RootState) => state.editorModeMapReducer);
  const keplerUpdate = useSelector((state: RootState) => state.keplerUpdateListener);
  const keplerMapState = useSelector((state: RootState) => state.keplerMapState);
  const selectedEvent = useSelector((state: RootState) => state.event);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedEvent?.event) {
      return;
    }

    const { lat, long } = selectedEvent.event;
    // get current zoom if currentZoom >
    const currentZoom = mapBoxRef.getZoom();
    const newZoom = selectedEvent.ignoreZoom ? 8 : currentZoom > 8 ? currentZoom : 8;
    updateMapState(lat, long, newZoom);
  }, [selectedEvent]);

  useEffect(() => {
    if (!currentMap) {
      return;
    }
    setCurrentMapUrl(currentMap.url);
    if (mapBoxRef) {
      mapBoxRef.setStyle(currentMap.url);
    }
  }, [currentMap, mapBoxRef]);

  useEffect(() => {
    /*
      The order in which the categories are found is very important if we want kepler to correctly,
      paint the points in the desired color with its built-in capabilities.
      This function retrieves the actual order after each filtering of the data.
    */
    const addCategory = colorRangeManager.addCategories();
    const data = searchEngine.getData();

    for (let i = 0; i < data.rows.length; i++) {
      const category = data.rows[i][searchEngine.currentFields["source"]];
      addCategory(category);
    }
    addCategory();
  }, [searchEngine, filteredIndexes, keplerMapState]);

  useEffect(() => {
    const actions = KeplerMapFilters.setKeplerDateRange(keplerMapState.dateRange);
    if (!actions) {
      return;
    }

    const keplerSelector = getKeplerSelector();
    if (keplerSelector?.map?.visState?.filters?.length === 0) {
      return;
    }

    batch(() => {
      dispatch(actions[0]);
      dispatch(actions[1]);
    });
  }, [keplerMapState.dateRange]);

  useEffect(() => {
    const keplerSelector = getKeplerSelector();
    if (!keplerSelector?.map?.visState) {
      return;
    }

    const layer = keplerSelector.map.visState.layers[EDITOR_MODE_DATA_LAYER_INDEX];

    let tasks: Func[] = [];
    // clear all polygon filters
    keplerSelector.map.visState.filters.forEach((item: any, index: number) => {
      if (item.type !== "polygon") {
        return;
      }
      tasks.push(() => {
        dispatch(KeplerMapFilters.setFilterValue(index, [], "layerId"));
      });
    });

    batch(() => {
      tasks.forEach((task) => task());
    });
    if (!editorModeSelector.editorMode) {
      return;
    }

    dispatch(KeplerMapFilters.setPolygonFilterLayerAction(editorModeSelector.feature, layer));
  }, [editorModeSelector.editorMode, editorModeSelector.featureId]);

  useEffect(() => {
    if (getRTLTextPluginStatus() !== 'unavailable') {
      return;
    }
    setRTLTextPlugin(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
      (): void => {},
      true // Lazy load the plugin only when text is in arabic
    );
  });

  useEffect(() => {
    if (!keplerIsReady()) {
      return;
    }

    applyLayerMode();
  }, [layerMode]);

  useEffect(() => {
    // Here we update date range of kepler timeline component.
    const action = KeplerMapFilters.setKeplerMapAnimationDataRange(searchEngine.filters.dateRange);
    if (!action) {
      return;
    }
    dispatch(action);
  }, [searchEngine.filters.dateRange, KeplerMapFilters, dispatch, DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID]);

  useEffect(() => {
    const buttonBlockElement = controlButtonsBlock.current;
    let timeline = document.querySelector(".kepler-gl .bottom-widget--container") as HTMLElement;
    if (!timeline || !mapBoxRef) {
      return;
    }

    let controller: any;
    const updateTimeLineBlock = () => {
      timeline = document.querySelector(".kepler-gl .bottom-widget--container") as HTMLElement;
      if (!timeline) {
        return;
      }

      if (Object.keys(groupIndexesByVerifiedDate(filteredIndexes)).length <= 2) {
        timeline.style.display = "none";
      } else {
        timeline.style.display = "";
      }

      const openStateCallback = (open: boolean) => {
        if (open === timelineOpen) {
          return;
        }
        setTimeLineOpen(open);
      };

      controller = HTMLTimelineControlButton(timeline, timelineOpen, openStateCallback, buttonBlockElement);
      controller.add();
    };
    updateTimeLineBlock();

    const updateTimeLine = () => {
      controller.remove();
      updateTimeLineBlock();
    };

    mapBoxRef.on("resize", updateTimeLine);
    return () => {
      controller.remove();
      mapBoxRef.off("resize", updateTimeLine);
    };
  }, [filteredIndexes, timelineOpen, controlButtonsBlock, keplerUpdate, mapBoxRef]);

  const groupIndexesByVerifiedDate = (indexes: number[]) => {
    const dct: Record<number, number[]> = {};

    for (let i = 0; i < indexes.length; i++) {
      const row = searchEngine.data[indexes[i]][0].properties;
      const date = new Date(row.verifiedDate).getTime();

      !dct[date] ? (dct[date] = [indexes[i]]) : dct[date].push(indexes[i]);
    }

    return dct;
  };

  const moveMapHandler = () => {
    /*
      This is left over from the old implementation, which still contained the OnlyEventsOnMapFrame filter.
      This function has not been removed so that it can be quickly returned in the future.
    */
    if (!keplerMapState.ready || !mapDatasetsIsAvailable()) {
      return;
    }

    if (!searchEngine.filters.onlyEventsMapFrame) {
      return;
    }
    const bounds = mapBoxRef.getBounds();

    const boundsRect: BoundingRect = {
      long: [bounds._sw.lng, bounds._ne.lng],
      lat: [bounds._sw.lat, bounds._ne.lat],
    };
    const actions = KeplerMapFilters.setKeplerOnlyMapFrameEvents(boundsRect);
    batch(() => {
      dispatch(actions[0]);
      dispatch(actions[1]);
    });
  };

  useEffect(() => {
    let domainBoundingRect: BoundingRect | null;
    if (searchEngine.filters.onlyEventsMapFrame) {
      domainBoundingRect = getCurrentViewBoundingRect();
    } else {
      domainBoundingRect = getDomainBoundingRect();
    }

    if (!domainBoundingRect) {
      return;
    }
    const actions = KeplerMapFilters.setKeplerOnlyMapFrameEvents(domainBoundingRect);
    batch(() => {
      dispatch(actions[0]);
      dispatch(actions[1]);
    });
  }, [searchEngine.filters.onlyEventsMapFrame]);

  useEffect(() => {
    const data = searchEngine.getData();
    let currentZoom: number = 15;
    if (mapBoxRef) {
      currentZoom = mapBoxRef.getZoom();
    }

    let updateKeplerMapState = () => {
      dispatch({
        type: keplerExtension.UPDATE_KEPLER_MAP_STATE,
        payload: {
          zoom: currentZoom > 15 ? 15 : currentZoom,
        },
      });
    };

    if (compareArrays<number>(filteredIndexes, oldFilteredIndexes) && mapDatasetsIsAvailable()) {
      dispatch({
        type: keplerExtension.UPDATE_KEPLER_GL,
      });
      return;
    } else if ((data.rows.length === 0 && mapBoxRef) || searchEngine.saveCurrentZoomAndCoordinates) {
      if (!mapBoxRef) {
        return;
      }
      const { lat, lng } = mapBoxRef.getCenter();
      const zoom = mapBoxRef.getZoom();

      updateKeplerMapState = () => {
        updateMapState(lat, lng, zoom);
      };
    }

    dispatch(addDataToKeplerMapActionCreator(data, false, DefaultKeplerFilters.genConfig(data.rows.length)));
    setDefaultSettingsForEditorLayer();
    applyLayerMode();
    if (mapBoxRef) {
      moveMapHandler();
    }
    setOldFilteredIndexes(filteredIndexes);
    if (keplerIsReady()) {
      setLatLongDomain();
      updateKeplerMapState();
    }

    if (!searchEngine.saveCurrentZoomAndCoordinates) {
      setupCorrectZoom();
      searchEngine.saveCurrentZoomAndCoordinates = true;
    }
  }, [filteredIndexes]);

  useEffect(() => {
    /*
      this useEffect listen when the user move or zoom map.
      mapBoxRef is a direct link to the mapBox obj in the keplerGl library, which allows you to control the mapBox object directly.
   */
    if (!mapBoxRef || !keplerIsReady()) {
      return;
    }

    if (currentMapUrl) {
      mapBoxRef.setStyle(currentMapUrl);
    }

    mapBoxRef.on("move", moveMapHandler);
    return () => {
      mapBoxRef.off("move", moveMapHandler);
    };
  }, [mapBoxRef]);

  const setDefaultSettingsForEditorLayer = () => {
    if (!keplerMapState.entryReady) {
      return;
    }

    const applyEditorConfig = enablePointMode(
      {
        name: "lat",
        fieldIndex: searchEngine.currentFields["lat"],
      },
      {
        name: "long",
        fieldIndex: searchEngine.currentFields["long"],
      },
      {
        name: "rad",
        fieldIndex: searchEngine.currentFields["rad"],
      },
      EDITOR_MODE_DATA_LAYER_INDEX
    );

    applyEditorConfig(dispatch);
  };

  const applyLayerMode = useCallback(() => {
    if (!keplerMapState.entryReady) {
      return;
    }

    switch (layerMode) {
      case LayerModes.Geojson:
        const applyPointMode = enableGeojsonMode(
          {
            fieldName: "category",
            fieldIndex: searchEngine.currentFields["category"],
          },
          "string",
          searchEngine.proxyData,
          {
            name: "_geojson",
            fieldIndex: searchEngine.currentFields["_geojson"],
          }
        );
        applyPointMode(dispatch);
        break;
      case LayerModes.Heatmap:
        const applyHeatMode = enableHeatMode(
          searchEngine.currentFields["lat"],
          searchEngine.currentFields["long"],
          HEAT_MAP_COLOR_RANGE
        );
        applyHeatMode(dispatch);
        break;
    }
  }, [layerMode, dispatch, searchEngine, keplerMapState]);

  const setupCorrectZoom = () => {
    if (!mapBoxRef) {
      return;
    }

    const { max, min } = searchEngine.dataRect;
    const { lat, long } = searchEngine.currentCenter;
    if (rectIsValid(searchEngine.dataRect)) {
      const { zoom } = mapBoxRef.cameraForBounds([
        [min.long, min.lat],
        [max.long, max.lat],
      ]);
      const newZoom = validateZoom(zoom);
      dispatch({
        type: keplerExtension.UPDATE_KEPLER_MAP_STATE,
        payload: {
          zoom: newZoom > 15 ? 15 : newZoom,
          latitude: lat,
          longitude: long,
        },
      });
    }
  };

  const setLatLongDomain = () => {
    const domainBoundingRect = getDomainBoundingRect();
    if (domainBoundingRect) {
      const actions = KeplerMapFilters.setKeplerOnlyMapFrameEvents(domainBoundingRect);
      batch(() => {
        dispatch(actions[0]);
        dispatch(actions[1]);
      });
    }
  };

  const getCurrentViewBoundingRect = (): BoundingRect | null => {
    if (!mapBoxRef) {
      return null;
    }
    const bounds = mapBoxRef.getBounds();

    return {
      long: [bounds._sw.lng, bounds._ne.lng],
      lat: [bounds._sw.lat, bounds._ne.lat],
    };
  };

  const getDomainBoundingRect = (): BoundingRect | null => {
    const { max, min } = searchEngine.dataRect;

    return {
      long: [min.long, max.long],
      lat: [min.lat, max.lat],
    };
  };

  const mapDatasetsIsAvailable = (): boolean => {
    const state = getKeplerSelector();
    const datasets = state?.map?.visState?.datasets;

    return datasets ? Object.keys(datasets).length > 0 : false;
  };

  const toggleLayerMode = () => {
    switch (layerMode) {
      case LayerModes.Heatmap:
        setLayerMode(LayerModes.Geojson);
        break;
      case LayerModes.Geojson:
        setLayerMode(LayerModes.Heatmap);
        break;
    }
  };

  const keplerIsReady = (): boolean => {
    const keplerSelector = getKeplerSelector();
    return Boolean(keplerSelector?.map);
  };

  const updateMapState = (latitude: number, longitude: number, zoom: number) => {
    dispatch({
      type: keplerExtension.UPDATE_KEPLER_MAP_STATE,
      payload: {
        latitude: latitude,
        longitude: longitude,
        zoom: zoom,
      },
    });
  };

  const getHoverPoint = (keplerState: any): boolean | any => {
    if (keplerState?.keplerGl?.map?.visState?.hoverInfo) {
      return keplerState.keplerGl.map.visState.hoverInfo.object;
    } else {
      return false;
    }
  };

  const getCurrentZoom = (): number | null => {
    if (!keplerIsReady() || !mapBoxRef) {
      return null;
    }
    return mapBoxRef.getZoom();
  };

  const getCurrentCoordinates = (): { latitude: number; longitude: number } | null => {
    if (!keplerIsReady() || !mapBoxRef) {
      return null;
    }
    const { lat, lng } = mapBoxRef.getCenter();
    return {
      latitude: lat,
      longitude: lng,
    };
  };

  const isDateRangeFilterReady = (state: any): boolean => {
    let filters = state?.keplerGl?.map?.visState?.filters;
    if (!filters || filters.length === 0) {
      return false;
    }
    filters = filters.slice();

    if (!filters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID]) {
      return false;
    }
    return true;
  };

  const setDefaultDateRange = (state: any) => {
    const { searchEngine, initialStateChanged } = state.searchEngine;
    if (initialStateChanged) {
      // if initial filters state changed we don't need to set initial DateRange
      return state;
    }

    let dateRange = searchEngine.filters.dateRange; // if initial dateRange we don't need to set initial DateRange value
    if (!dateRange) {
      return state;
    }

    if (!isDateRangeFilterReady(state)) {
      return state;
    }

    const range = dateRange.map((item: Date) => item.getTime());
    let newFilters = state?.keplerGl?.map?.visState?.filters;

    newFilters[DefaultKeplerFilters.publicFilters.VERIFIED_DATA_ID].value = range;
    return {
      ...state,
      keplerGl: {
        ...state.keplerGl,
        map: {
          ...state.keplerGl.map,
          visState: {
            ...state.keplerGl.map.visState,
            filters: newFilters,
          },
        },
      },
    };
  };

  const onHoverPointKeplerMap = (state: any) => {
    const deckGlOverlay = document.querySelector("#default-deckgl-overlay") as HTMLElement;
    if (deckGlOverlay) {
      const hoverPoint = getHoverPoint(state);
      deckGlOverlay.style.cursor = hoverPoint ? "pointer" : "";
      if (hoverPoint?.geometry?.type === "LineString") {
        state.keplerGl.map.visState.hoverInfo = null;
        return;
      }
      openCustomToolTip(hoverPoint);
    }
  };

  const getKeplerStateMiddleware = (state: any) => {
    onHoverPointKeplerMap(state);
    /*
      Current function set initial DateRange it's to save dateRange state which got from searchParams, because
      kepler doesn't have a api to do it directly and kepler after every each addDataToMap action resets all settings.
      Yeah this function is bullshit.
     */
    state = setDefaultDateRange(state);

    return state.keplerGl;
  };

  const changeZoom = (newZoom: number) => {
    const currentCoordinates = getCurrentCoordinates();
    if (!currentCoordinates) {
      return;
    }

    updateMapState(currentCoordinates.latitude, currentCoordinates.longitude, newZoom);
  };

  const validateZoom = (zoom: number): number => {
    if (zoom >= MAX_ZOOM) {
      return MAX_ZOOM;
    } else if (zoom <= MIN_ZOOM) {
      return MIN_ZOOM;
    }

    return zoom;
  };

  const zoomIn = () => {
    const zoom = getCurrentZoom();
    if (!zoom) {
      return;
    }

    let newZoom = validateZoom(zoom + ZOOM_STEP);
    changeZoom(newZoom);
  };

  const zoomOut = () => {
    const zoom = getCurrentZoom();
    if (!zoom) {
      return;
    }

    let newZoom = validateZoom(zoom - ZOOM_STEP);
    changeZoom(newZoom);
  };

  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <KeplerGl
            id="map"
            mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
            getState={getKeplerStateMiddleware}
            width={width}
            height={height}
            getMapboxRef={(mapBoxRef: any) => {
              if (!mapBoxRef) {
                return;
              }
              setMapBoxRef(mapBoxRef.getMap());
            }}
          />
        )}
      </AutoSizer>
      <div ref={controlButtonsBlock} className={styles.mapControllerWrapper}>
        <MapController layerMode={layerMode} toggleLayerMode={toggleLayerMode} zoomIn={zoomIn} zoomOut={zoomOut} />
      </div>
      <TimelineManager
        mapBox={mapBoxRef}
        onOpenChange={(open: boolean) => {
          setTimeLineOpen(open);
        }}
      />
      <div className={styles.keplerMapButtons}>
        <ActiveList components={[InfoPopup, EditorModePopup, ExportPdf, LegendPopup]} />
      </div>
    </>
  );
};
