interface ViolenceLevelInterface {
  name: string,
  color: string,
  padding: string, }


export const violenceLevels: ViolenceLevelInterface[] = [
{ name: "None", color: "#ff000000", padding: "5px 0" },
{ name: "Mild", color: "#ff000000", padding: "5px 0" },
{ name: "Moderate", color: "#ff000000", padding: "5px 0" },
{ name: "Severe", color: "#b25252", padding: "5px 10px" },
{ name: "Very Severe", color: "#963838", padding: "5px 10px" },
{ name: "Very Severe", color: "#963838", padding: "5px 10px" }];