import { Link, useLocation } from "react-router-dom";
import { Menu } from "../../pages/home/menu";
import logo from "../../images/sudanShahidHeaderLogo.svg";
import style from "./header.module.css";
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { i18n } = useTranslation();
  const locations = useLocation();

  const handleChangeLanguage = (lang: string) => {
    console.log("changing from",i18n.language);
    console.log("changing to",lang);
    i18n.changeLanguage(lang);
    // persist change to local storage
    if (localStorage.getItem('i18nextLng') === null || localStorage.getItem('i18nextLng') === lang) {
      localStorage.setItem('i18nextLng', lang);
    }
    // refresh the page to force rerender of all components
    window.location.reload();
  };

  return (
    <div className={style.header}>
      <Link to="/" className={style.headerLink}>
        <img className={style.logo} src={logo} alt="Sudan Shahid" />
      </Link>
      <Menu selectedLanguage={"en"} onChangeLanguage={handleChangeLanguage} />
    </div>);

};