import { combineReducers, createStore, applyMiddleware } from "redux";
import keplerGlReducer from "kepler.gl/reducers";
import { taskMiddleware } from "react-palm/tasks";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import { composeWithDevTools } from "redux-devtools-extension";
import homeReducer from "../pages/home/reducer";
import mainReducer from "../pages/reducer";
import searchFilterReducer from "../pages/home/search/reducer";
import EventReducer from "../pages/home/event/reducer";
import { keplerExtensionComposer } from "../pages/keplergl/reducerExtensions/keplerReduxExtension";
import { editorModeMapReducer } from "../pages/keplergl/reducers/editorModeReducer";
import { keplerMapStateReducer } from "../pages/keplergl/reducers/keplerMapStateReducer";
import { taskReducer } from "./taskReducer";

const initialState = {};

export const DEFAULT_MAP_STATE = {
  zoom: 4.5,
  latitude: 13.5518,
  longitude: 30.5324,
  minZoom: 2.5
};

// its for debug
const urlSearchParams = new URLSearchParams(window.location.search);

const keplerReducer = keplerGlReducer.initialState({
  uiState: {
    activeSidePanel: null,
    currentModal: null,
    readOnly: urlSearchParams.get("debug") === "true" ? false : true // its for debug
  },
  mapState: DEFAULT_MAP_STATE
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action: any) => {
    if (action.type === "MAIN_SET_USER_DATA") {
      return {
        ...action,
        accessToken: null
      };
    }
    return action;
  },
  stateTransformer: (state: any) => {
    // Transform the state to remove sensitive information
    return {
      ...state,
      accessToken: "token"
    };
  }
});

const reducers = combineReducers({
  keplerGl: keplerReducer,
  editorModeMapReducer: editorModeMapReducer,
  keplerUpdateListener: (state: boolean = false, _: any) => state,
  taskReducer: taskReducer,
  keplerMapState: keplerMapStateReducer,
  home: homeReducer,
  main: mainReducer,
  searchEngine: searchFilterReducer,
  event: EventReducer
});

const composeEnhancers = composeWithDevTools({});

export type RootState = ReturnType<typeof reducers>;

export default createStore(
  keplerExtensionComposer(reducers),
  initialState,
  composeEnhancers(applyMiddleware(taskMiddleware, thunk), sentryReduxEnhancer)
);
/*
  I don't know why here is needed taskMiddleware but this wrote in keplerGl documentation:

  "Kepler.gl uses Redux to manage its internal state,
  along with react-palm middleware to handle side effects. Mount kepler.gl reducer in your store, apply taskMiddleware."
 */