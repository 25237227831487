import i18n from 'i18next';import React from "react";
import cn from "classnames";
import styles from "./menu.module.css";
import { Link, useLocation } from "react-router-dom";
import LocalizationIcon from "../../../images/localization-icon.svg";
import { Dropdown } from "antd";
import { useTranslation } from 'react-i18next';

type MenuProps = { selectedLanguage: string, onChangeLanguage: (lang: string) => void, };

export const Menu: React.FC<MenuProps> = (props) => {
  const locations = useLocation();
  const { t, i18n } = useTranslation();

  const dataNavigations = [
  {
    path: "/",
    title: i18n.t("MAP")
  },
  {
    path: "/about",
    title: i18n.t("ABOUT")
  }];


  const items = [
  {
    key: '1',
    label:
    <a rel="noopener noreferrer" onClick={() => props.onChangeLanguage("en")}>
          {i18n.t("ENGLISH")}
        </a>

  },
  {
    key: '2',
    label:
    <a rel="noopener noreferrer" onClick={() => props.onChangeLanguage("ar")}>
          {i18n.t("ARABIC")}
        </a>

  }];


  return (
    <div className="menu">
      <nav className={styles.navigation}>
        {dataNavigations.map((item) => {
          return (
            <Link
              key={item.title}
              to={item.path}
              className={cn(styles.navItem, locations.pathname === item.path && styles.activeNav)}>

              {item.title}
            </Link>);

        })}
        <Dropdown
          menu={{
            items
          }}
          placement="bottom">

          <a className={cn(styles.navItem, styles.localizationButton)}>
            <img src={LocalizationIcon} alt="Select Language" />
          </a>
        </Dropdown>
      </nav>
    </div>);

};