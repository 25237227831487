import { event } from "../../../constants/actionTypes";
import { EventProperties } from "../../../interfaces/events";

export type EventState = {
  event: EventProperties | null,
  ignoreZoom: boolean, };

interface EventReducerAction {
  type: string,
  payload: EventState | null, }

const initialState = null;

const EventReducer = (state: EventState | null = initialState, action: EventReducerAction) => {
  const { type, payload } = action;
  switch (type) {
    case event.OPEN_MODAL:
      state = payload;
      return state;
    case event.CLOSE_MODAL:
      state = null;
      return state;
    default:
      return state;
  }
};

export const createOpenModalEventAction = (payload: EventState): EventReducerAction => {
  return {
    type: event.OPEN_MODAL,
    payload: payload
  };
};

export const createCloseModalEventAction = (): EventReducerAction => {
  return {
    type: event.CLOSE_MODAL,
    payload: null
  };
};

export default EventReducer;