import React from "react";
import { Route, Routes } from "react-router-dom";
import { notification } from "antd";
import { Home } from "./pages/home";
import { Layout } from "./components/layout";
import "antd/dist/antd.min.css";

notification.config({
  maxCount: 1
});

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>);

}

export default App;