import { ActionTypes as KeplerActionTypes } from "kepler.gl/actions";
import { visStateUpdaters } from "kepler.gl/reducers";
import { batch } from "react-redux";
import { keplerExtension } from "../../../constants/actionTypes";
import {
  EDITOR_MODE_DATA_LAYER_INDEX,
  EVENTS_DATA_LAYER_INDEX } from
"../keplerMapUtils";
import { registerTask } from "../../../store/taskReducer";
import { RootState } from "../../../store";

interface FieldData {
  name: string,
  fieldIndex: number, }


const actionMeta = {
  _addr_: "@@KG_MAP",
  _forward_: "@redux-forward/FORWARD"
};
const payloadMeta = { _id_: "map" };

const EVENTS_DATA_LAYER_VIS_CONFIG = {
  thickness: 3,
  radius: 22,
};


const EVENTS_DATA_LAYER_CONFIG = {
  radius: 22,
};

const setColorRangeAction = (colorRange: string[], layerIndex: number) => {
  return {
    type: keplerExtension.SET_COLOR_RANGE,
    payload: {
      layerIndex: layerIndex,
      config: {
        colorRange: {
          category: "Custom",
          colors: colorRange,
          name: "Custom Palette",
          type: "custom"
        }
      }
    }
  };
};

const initGeojsonModeAction = (layerIndex: number) => {
  return {
    type: keplerExtension.ENABLE_GEO_JSON_MODE,
    payload: {
      layerIndex: layerIndex
    }
  };
};

const initPointModeAction = (layerIndex: number) => {
  return {
    type: keplerExtension.ENABLE_POINT_MODE,
    payload: {
      layerIndex: layerIndex
    }
  };
};

const getGeojsonModeConfig = (fieldName: string, fieldIndex: number, layerIndex: number) => {
  return {
    type: keplerExtension.SET_GEO_JSON_CONFIG,
    payload: {
      config: {
        columns: {
          geojson: { value: fieldName, fieldIdx: fieldIndex }
        }
      },
      layerIndex: layerIndex
    }
  };
};

const getPointModeConfig = (
latName: string,
latIndex: number,
longName: string,
longIndex: number,
radName: string,
radIndex: number,
layerIndex: number) =>
{
  return {
    type: keplerExtension.SET_POINT_CONFIG,
    payload: {
      config: {
        columns: {
          lat: { value: latName, fieldIdx: latIndex },
          lng: { value: longName, fieldIdx: longIndex },
          rad: { value: radName, fieldIdx: radIndex },
          altitude: { value: null, fieldIdx: -1, optional: true }
        }
      },
      layerIndex: layerIndex
    }
  };
};

export const enableGeojsonMode = (
colorField: {
  fieldName: string,
  fieldIndex: number, },

type: string,
storage: any[],
dataField: FieldData) =>
{
  const initAction = initGeojsonModeAction(EVENTS_DATA_LAYER_INDEX);
  const configAction = getGeojsonModeConfig(dataField.name, dataField.fieldIndex, EVENTS_DATA_LAYER_INDEX);
  const config = changeLayerVisConfig(EVENTS_DATA_LAYER_VIS_CONFIG, EVENTS_DATA_LAYER_INDEX);
  const config_layer = changeLayerConfig(EVENTS_DATA_LAYER_CONFIG, EVENTS_DATA_LAYER_INDEX);

  return (dispatch: any) => {
    batch(() => {
      dispatch(initAction);
      dispatch(configAction);
      dispatch(editorModeLayerStyleConfig());
      dispatch(config);
      dispatch(config_layer);
    });
  };
};

export const enablePointMode = (lat: FieldData, long: FieldData, rad: FieldData, layerIndex: number) => {
  const initAction = initPointModeAction(EDITOR_MODE_DATA_LAYER_INDEX);
  const configAction = getPointModeConfig(lat.name, lat.fieldIndex, long.name, long.fieldIndex, rad.name, rad.fieldIndex, layerIndex);

  return (dispatch: any) => {
    batch(() => {
      dispatch(initAction);
      dispatch(configAction);
    });
  };
};

const initHeatModeAction = (layerIndex: number) => {
  return {
    type: keplerExtension.ENABLE_HEAT_MAP_MODE,
    payload: {
      layerIndex: layerIndex
    }
  };
};

const getHeatMapModeConfig = (latitudeFieldIndex: number, longitudeFieldIndex: number, layerIndex: number) => {
  return {
    type: keplerExtension.SET_HEAT_MAP_CONFIG,
    payload: {
      config: {
        columns: {
          lat: { value: "lat", fieldIdx: latitudeFieldIndex },
          lng: { value: "long", fieldIdx: longitudeFieldIndex }
        }
      },
      layerIndex: layerIndex
    }
  };
};

const getHeatMapLayerConfig = (layerIndex: number) => {
  return {
    type: keplerExtension.SET_LAYER_VIS_CONFIG,
    payload: {
      config: {
        stroked: false,
        filled: true
      },
      layerIndex: layerIndex
    }
  };
};

export const enableHeatMode = (latitudeFieldIndex: number, longitudeFieldIndex: number, colorRange: string[]) => {
  const initAction = initHeatModeAction(EVENTS_DATA_LAYER_INDEX);
  const configAction = getHeatMapModeConfig(latitudeFieldIndex, longitudeFieldIndex, EVENTS_DATA_LAYER_INDEX);
  const layerConfigAction = getHeatMapLayerConfig(EVENTS_DATA_LAYER_INDEX);

  const colorRangeConfigAction = setColorRangeAction(colorRange, EVENTS_DATA_LAYER_INDEX);
  return (dispatch: any) => {
    batch(() => {
      dispatch(initAction);
      dispatch(configAction);
      dispatch(colorRangeConfigAction);
      dispatch(layerConfigAction);
      dispatch(editorModeLayerStyleConfig());
    });
  };
};

export const disableSelectedFeature = () => {
  return {
    meta: actionMeta,
    payload: {
      feature: undefined,
      meta: payloadMeta,
      type: KeplerActionTypes.SET_SELECTED_FEATURE
    },
    type: KeplerActionTypes.SET_SELECTED_FEATURE
  };
};

const editorModeLayerStyleConfig = () => {
  return changeLayerVisConfig(
    {
      stroked: false,
      filled: false
    },
    EDITOR_MODE_DATA_LAYER_INDEX
  );
};

const changeLayerVisConfig = (config: any, layerIndex: number) => {
  return {
    type: keplerExtension.SET_LAYER_VIS_CONFIG,
    payload: {
      config,
      layerIndex
    }
  };
};

export const changeLayerConfig = (config: any, layerIndex: number) => {
  return {
    type: keplerExtension.SET_LAYER_CONFIG,
    payload: {
      config,
      layerIndex
    }
  };
};

const changeLayerStyle = (state: any, newLayerType: string, layerIndex: number) => {
  return {
    ...state,
    keplerGl: {
      ...state.keplerGl,
      map: {
        ...state.keplerGl.map,
        visState: visStateUpdaters.layerTypeChangeUpdater(state.keplerGl.map.visState, {
          oldLayer: state.keplerGl.map.visState.layers[layerIndex],
          newType: newLayerType
        })
      }
    }
  };
};

const setLayerConfig = (state: any, newConfig: any, layerIndex: number) => {
  return {
    ...state,
    keplerGl: {
      ...state.keplerGl,
      map: {
        ...state.keplerGl.map,
        visState: visStateUpdaters.layerConfigChangeUpdater(state.keplerGl.map.visState, {
          oldLayer: state.keplerGl.map.visState.layers[layerIndex],
          newConfig: newConfig
        })
      }
    }
  };
};

const setLayerVisConfig = (state: any, newVisConfig: any, layerIndex: number) => {
  return {
    ...state,
    keplerGl: {
      ...state.keplerGl,
      map: {
        ...state.keplerGl.map,
        visState: visStateUpdaters.layerVisConfigChangeUpdater(state.keplerGl.map.visState, {
          oldLayer: state.keplerGl.map.visState.layers[layerIndex],
          newVisConfig: newVisConfig
        })
      }
    }
  };
};

const setNewColorRange = (state: any, newVisConfig: any, layerIndex: number) => {
  return setLayerVisConfig(state, newVisConfig, layerIndex);
};

const setSelectedFeature = (state: any, action: any) => {
  const selectedFeature = action.payload.feature;
  const editorMode = !!selectedFeature;

  const newEditorModeMapState = {
    ...state.editorModeMapReducer,
    editorMode: editorMode
  };
  if (selectedFeature) {
    newEditorModeMapState.feature = selectedFeature;
    newEditorModeMapState.featureId = selectedFeature.id;
  }

  return {
    ...state,
    editorModeMapReducer: newEditorModeMapState
  };
};

const setFeatures = (state: any, action: any) => {
  const featureId = state.editorModeMapReducer.feature.id;

  const selectedFeature = action.payload.features.find((item: any) => {
    return item.id === featureId;
  });

  return {
    ...state,
    editorModeMapReducer: {
      ...state.editorModeMapReducer,
      feature: selectedFeature,
      featureId: featureId
    }
  };
};

const disableEditorMode = (state: any) => {
  return {
    ...state,
    editorModeMapReducer: {
      ...state.editorModeMapReducer,
      editorMode: false
    }
  };
};

const setKeplerMapStateReadyStatus = (state: any, status: boolean) => {
  return {
    ...state,
    keplerMapState: {
      ...state.keplerMapState,
      entryReady: status
    }
  };
};

const setKeplerMapStateReady = (state: any) => {
  return {
    ...state,
    keplerMapState: {
      ...state.keplerMapState,
      ready: true
    }
  };
};

const setTimeFilter = (() => {
  let timeRangeReady = false;
  return (state: RootState, action: any) => {
    const dateRange = action.payload.value;
    const initialDateRange = state.searchEngine.searchEngine.filters.dateRange;

    if (!initialDateRange) {
      timeRangeReady = true;
    }
    const newState = {
      ...state,
      keplerMapState: {
        ...state.keplerMapState,
        dateRange: dateRange
      },
      searchEngine: {
        ...state.searchEngine,
        initialStateChanged: timeRangeReady
      }
    };

    timeRangeReady = true;

    return newState;
  };
})();

const changeEditorDrawModeActionCreator = (mode: string) => {
  return {
    type: KeplerActionTypes.SET_EDITOR_MODE,
    meta: actionMeta,
    payload: {
      meta: payloadMeta,
      mode: mode,
      type: KeplerActionTypes.SET_EDITOR_MODE
    }
  };
};

const keplerGlToolTipState = (state: any, open: boolean) => {
  state.keplerGl = {
    ...state.keplerGl,
    map: {
      ...state.keplerGl.map,
      visState: {
        ...state.keplerGl.map.visState,
        clicked: null,
        interactionConfig: {
          ...state.keplerGl.map.visState.interactionConfig,
          tooltip: {
            ...state.keplerGl.map.visState.interactionConfig.tooltip,
            enabled: open
          }
        }
      }
    }
  };
  return state;
};

const getPickedPoint = (keplerState: any) => {
  if (!keplerState?.keplerGl?.map || !keplerState?.keplerGl?.map?.visState?.clicked) {
    return null;
  }

  const clicked = keplerState.keplerGl.map.visState.clicked;
  return [clicked.object.properties, keplerState.keplerGl.map.visState.mousePos.mousePosition];
};

const onPickKeplerMapPoint = (state: any) => {
  const pointProperties = getPickedPoint(state);

  if (pointProperties) {
    state.event = { event: { ...pointProperties[0] }, ignoreZoom: false };
    state = keplerGlToolTipState(state, false);
  } else {
    if (state.keplerGl?.map) {
      state = keplerGlToolTipState(state, true);
    }
  }

  return state;
};

const softZoomUpdate = (state: any, action: any) => {
  const currentZoom = state.keplerGl.map.mapState.zoom;

  if (currentZoom > action.payload.zoom) {
    return state;
  }

  return {
    ...state,
    keplerGl: {
      ...state.keplerGl,
      map: {
        ...state.keplerGl.map,
        mapState: {
          ...state.keplerGl.map.mapState,
          zoom: action.payload.zoom
        }
      }
    }
  };
};

const loadFiles = (state: any, action: any) => {
  const visState = { ...state.keplerGl.map.visState };
  const newVisState = visStateUpdaters.loadFilesUpdater(visState, {
    files: action.payload.files
  });

  newVisState.fileLoading.onFinish = (result: any) => {
    return {
      type: KeplerActionTypes.LOAD_FILES_SUCCESS,
      onFinish: action.payload.onFinish,
      result
    };
  };

  return {
    ...state,
    keplerGl: {
      ...state.keplerGl,
      map: {
        ...state.keplerGl.map,
        visState: { ...newVisState }
      }
    }
  };
};

export const cleanExportingImage = () => {
  const cleanAction = {
    type: KeplerActionTypes.CLEANUP_EXPORT_IMAGE,
    payload: { type: KeplerActionTypes.CLEANUP_EXPORT_IMAGE, meta: payloadMeta },
    meta: actionMeta
  };

  return (dispatch: any) => {
    dispatch(cleanAction);
  };
};

export const generateMapImage = (width: number, height: number) => {
  const startExportingImageAction = {
    type: KeplerActionTypes.SET_EXPORT_IMAGE_SETTING,
    payload: { type: KeplerActionTypes.SET_EXPORT_IMAGE_SETTING, payload: { exporting: true }, meta: { _id_: "map" } },
    meta: { _forward_: "@redux-forward/FORWARD", _addr_: "@@KG_MAP" }
  };

  const setSettingsImageAction = {
    type: KeplerActionTypes.SET_EXPORT_IMAGE_SETTING,
    payload: {
      type: KeplerActionTypes.SET_EXPORT_IMAGE_SETTING,
      payload: { mapW: width, mapH: height, resolution: "TWO_X" },
      meta: payloadMeta
    },
    meta: actionMeta
  };
  return (dispatch: any) => {
    batch(() => {
      dispatch(startExportingImageAction);
      dispatch(setSettingsImageAction);
    });
  };
};

export const keplerExtensionComposer = (reducersFunction: ((state: any, action: any) => any)) => {
  return (state: any, action: any) => {
    switch (action.type) {
      case KeplerActionTypes.LOAD_FILES_SUCCESS:
        const lastZoom = state.keplerGl.map.mapState.zoom;
        state = reducersFunction(state, action);
        action.onFinish();
        state.keplerGl.map.mapState.zoom = lastZoom;
        return state;
      case keplerExtension.LOAD_POINTS_FILE:
        state = loadFiles(state, action);
        break;
      case KeplerActionTypes.ADD_DATA_TO_MAP:
        state = setKeplerMapStateReady(state);
        state = reducersFunction(state, action);

        if (state?.keplerGl?.map?.visState?.editor) {
          state.editorModeMapReducer.editorMode = false;
          state.keplerGl.map.visState.editor.mode = "DRAW_RECTANGLE";
        }
        return state;
      case KeplerActionTypes.REGISTER_ENTRY:
        state = setKeplerMapStateReadyStatus(state, true);
        break;
      case KeplerActionTypes.DELETE_ENTRY:
        state = setKeplerMapStateReadyStatus(state, false);
        break;
      case KeplerActionTypes.SET_FILTER_ANIMATION_TIME:
        state = setTimeFilter(state, action);
        if (action.payload.idx === undefined) {
          return state;
        }
        break;
      case KeplerActionTypes.SET_FILTER:
        if (action.payload.idx === undefined) {
          return state;
        }
        break;
      case keplerExtension.SOFT_UPDATE_KEPLER_ZOOM_STATE:
        return softZoomUpdate(state, action);
      case keplerExtension.ENABLE_POINT_MODE:
        return changeLayerStyle(state, "point", action.payload.layerIndex);
      case keplerExtension.ENABLE_HEAT_MAP_MODE:
        return changeLayerStyle(state, "heatmap", action.payload.layerIndex);
      case keplerExtension.ENABLE_GEO_JSON_MODE:
        return changeLayerStyle(state, "geojson", action.payload.layerIndex);
      case keplerExtension.SET_HEAT_MAP_CONFIG:
        return setLayerConfig(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.SET_GEO_JSON_CONFIG:
        return setLayerConfig(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.SET_POINT_CONFIG:
        return setLayerConfig(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.SET_COLOR_RANGE:
        return setNewColorRange(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.SET_LAYER_VIS_CONFIG:
        return setLayerVisConfig(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.SET_LAYER_CONFIG:
        return setLayerConfig(state, action.payload.config, action.payload.layerIndex);
      case keplerExtension.UPDATE_KEPLER_MAP_STATE:
        return {
          ...state,
          keplerUpdateListener: !state.keplerUpdateListener,
          keplerGl: {
            ...state.keplerGl,
            map: {
              ...state.keplerGl.map,
              mapState: {
                ...state.keplerGl.map.mapState,
                ...action.payload
              }
            }
          }
        };
      case KeplerActionTypes.TOGGLE_MAP_CONTROL:
        if (action.payload.payload.panelId === "mapDraw") {
          state = registerTask(state, () => {
            return changeEditorDrawModeActionCreator("DRAW_RECTANGLE");
          });
        }
        break;
      case KeplerActionTypes.DELETE_FEATURE:
        state = disableEditorMode(state);
        break;
      case KeplerActionTypes.SET_EDITOR_MODE:
        state = disableEditorMode(state);
        break;
      case keplerExtension.UPDATE_KEPLER_GL:
        return {
          ...state,
          keplerGl: { ...state.keplerGl }
        };
      case KeplerActionTypes.SET_SELECTED_FEATURE:
        state = setSelectedFeature(state, action);
        break;
      case KeplerActionTypes.SET_FEATURES:
        if (state.editorModeMapReducer.editorMode) {
          state = setFeatures(state, action);
        }
        break;
    }

    state = onPickKeplerMapPoint(reducersFunction(state, action));
    return state;
  };
};