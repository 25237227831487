import React, { useState } from "react";

export interface ActiveListItem {
  onActive: () => void,
  onInActive: () => void,
  active: boolean, }

interface ActiveListInterface {
  components: React.FC<ActiveListItem>[] }


export const ActiveList = ({ components }: ActiveListInterface) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const onActive = (index: number) => {
    return () => {
      setActiveIndex(index);
    };
  };

  const onInActive = () => {
    setActiveIndex(-1);
  };

  return (
    <>
      {components.map((Item, index) => {
        return <Item key={index} onActive={onActive(index)} active={index === activeIndex} onInActive={onInActive} />;
      })}
    </>);

};