import i18n from 'i18next';import React from "react";
import { Collapse } from "antd";
import styles from "./about.module.css";

export const About = () => {
  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.innerContainer}>
        <h1 className={styles.about}>{i18n.t("ABOUT")}</h1>
        <div className={styles.aboutContainer}>
          <Collapse defaultActiveKey={["1", "2"]} bordered={false} expandIconPosition="end">
            <Collapse.Panel
              showArrow={true}
              className={styles.collapsePanel}
              header={i18n.t("ABOUT_THE_PROJECT_HEADER")}
              key="1">

              <div className={styles.collapseContent}>
                <p>
                  {i18n.t("IN_RESPONSE_TO_AN_OUTBREAK_OF")}
                </p>
                <p>
                  {i18n.t("C_ADS_IS_A_C_NONPROFIT_O")} 
                </p>
                <p>
                  {i18n.t("FOLLOWING_THE_ESCALATION_OF_VI")}  
                </p>
                {i18n.t("THE_MAP_ALSO_FEATURES_CONFLICT")}<a href="https://acleddata.com/about-acled/" target="_blank">
                  {i18n.t("ACLED")}
                </a>{i18n.t("_")} <p>{i18n.t("WE_ARE_GRATEFUL_TO_ACLED_FOR_P")}</p>
                <h3 style={{ color: "#fff" }}>{i18n.t("PARTNER_ORGANIZATIONS")}</h3>
                <p>
                  {i18n.t("THE_CENTRE_FOR_INFORMATION_RES")}
                </p>
                <p>
                 {i18n.t("THE_SUDAN_HUMAN_RIGHTS_HUB_HR")}
                </p>
                <p>
                  {i18n.t("SUDAN_SHAHID_CONCEPTUALLY_BUIL")}
                  <a href="https://eyesonrussia.org" target="_blank">
                  {" "}
                    {i18n.t("EYES_ON_RUSSIA_MAP")}
                  </a> {i18n.t("AND")} <a href="https://map.afghanwitness.org" target="_blank">
                    {" "}
                    {i18n.t("AFGHAN_WITNESS_MAP")}
                  </a>{i18n.t("_1")}
                </p>
                <p>
                  {i18n.t("THIS_PLATFORM_IS_NAMED_SUDAN_S")}
                </p>
                <p>
                  {i18n.t("ARTRIKA_DATABASE_IS_AN_ONLINE")}
                </p>
                <p>
                  {i18n.t("AND_MANY_OTHERS_WHO_HAVE_CHOSE")} 
                </p>
                <p>{i18n.t("WE_ARE_EAGER_TO_WELCOME_IN_NEW")}
              <a href="mailto:info@sudanshahid.org" target="_blank">
                  {" "}
                  {i18n.t("INFO_SUDANSHAHID_ORG")}
                </a>
              </p>
              <h3 style={{ color: "#fff" }}>{i18n.t("ABOUT_THE_DATA")}</h3>
              <p>
                {i18n.t("SUDAN_SHAHID_DRAWS_ON_A_UNIFIE")}
              </p>
              <ul className={styles.list}>
                <li>{i18n.t("CIR_OPEN_SOURCE_MEDIA_INCLUD")}</li>
                <li>{i18n.t("C_ADS_PARTNER_NETWORK_OPEN_A")}</li>
                <li>{i18n.t("SATELLITE_DATA_PROVIDED_OR_CA")}</li>
              </ul>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header={i18n.t("HOW_TO_USE_MAP_HEADER")} key="2">
              <div className={styles.collapseContent}>

                <p>
                {i18n.t("YOU_CAN_SELECT_THE_DATA_TO_BE")}
                </p>
                <ul className={styles.list}>
                  <li>{i18n.t("SELECT_ONE_OR_MORE_SOURCES_OF")}</li>
                  <li>{i18n.t("SELECT_ONE_OR_MORE_ACTORS_ALLE")}</li>
                  <li>{i18n.t("SELECT_START_AND_END_DATES_FOR")}</li>
                  <li>{i18n.t("SEARCH_FOR_KEY_WORDS_SUCH_AS_P")}</li>
                </ul>
                <p>
                {i18n.t("THESE_SEARCH_FUNCTIONS_CAN_BE")}
                </p>
                <p>
                  {i18n.t("THE_MAP_WILL_PRESENT_DATA_MATC")}
                </p>
                <ul className={styles.list}>
                  <li>
                    {i18n.t("HOVER_YOUR_CURSOR_OVER_A_PIN_T")}
                  </li>
                  <li>
                    {i18n.t("CLICK_ON_A_PIN_TO_DISPLAY_A_PR")}
                  </li>
                  <li>
                    {i18n.t("MOVE_THE_BRACKETS_ALONG_THE_TI")}
                  </li>
                  <li>
                    {i18n.t("INCREASE_OR_DECREASE_THE_MAGNI")}
                  </li>
                </ul>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header={i18n.t("DEFINITIONS_HEADER")} key="4">
              <div className={styles.collapseContent}>
                <div>
                  <p className={styles.underlinedText}>{i18n.t("CATEGORY_HARM_TO_PERSONS")}</p>
                  <ul className={styles.list}>
                    <li>
                    {i18n.t("ARREST_DETENTION_OR_ABDUCTION")}
                    </li>
                    <li>
                    {i18n.t("CIVILIAN_INJURY")}
                    </li>
                    <li>
                    {i18n.t("CIVILIAN_DEATH")}                    </li>
                    <li>
                    {i18n.t("HUMILIATION_OF_CIVILIANS")}                    </li>
                    <li>
                    {i18n.t("FORCED_RECRUITMENT_OR_CONSCRIP")}                    </li>
                    <li>
                    {i18n.t("CIVILIAN_PROPERTY_LOOTING_OR_O")}                    </li>
                    <li>
                    {i18n.t("DISPLACEMENT_OF_CIVILIANS")}                    </li>
                    <li>
                    {i18n.t("INTERROGATION_OR_TORTURE_OF_CI")}
                    </li><li>
                    {i18n.t("MASS_GRAVES_AND_BURIAL_SITES")}
                                        </li>
                    <li>
                    {i18n.t("UNEXPLODED_ORDINANCE")}                    </li>
                    <li>
                    {i18n.t("HARM_TO_COMBATANTS")}                    </li>
                    </ul>
                  <p className={styles.underlinedText}>{i18n.t("CATEGORY_FORMS_OF_VIOLENCE")}</p>
                  <ul className={styles.list}>
                    <li>
                    {i18n.t("GUNFIRE")} 
                    </li>
                    <li>
                    {i18n.t("BOMBING_OR_EXPLOSION")} 
                    </li>
                    <li>
                      {i18n.t("FIRE")}
                    </li>
                    <li>
                      {i18n.t("PHYSICAL_VIOLENCE")}
                    </li>
                    <li>
                      {i18n.t("AIRSTRIKES")}               
                    </li>
                  </ul>
                  <p className={styles.underlinedText}>{i18n.t("CATEGORY_MILITARY_ACTIVITIES")}</p>
                  <ul className={styles.list}>
                    <li>
                    {i18n.t("INCITEMENT_AND_HATE_SPEECH")} 
                    </li>
                    <li>
                    {i18n.t("FIRING_POSITIONS")} 
                    </li>
                    <li>
                    {i18n.t("ROADBLOCKS_OR_RESTRICTIONS_ON")} 
                    </li>
                    <li>
                    {i18n.t("LOCATIONS_AND_MOVEMENTS")} 
                    </li>
                    <li>
                    {i18n.t("FORMS_OF_EQUIPMENT_OR_MUNITION")}   
                    </li>
                    <li>
                    {i18n.t("CAPTURE_OF_OPPOSING_COMBATANTS")} 
                    </li>
                  </ul>
                  <p className={styles.underlinedText}>{i18n.t("CATEGORY_DAMAGE_TO_PROPERTY")}</p>
                  <ul className={styles.list}>
                    <li>
                    {i18n.t("CIVILIAN_PROPERTY_DAMAGE")} 
                    </li>
                    <li>
                    {i18n.t("MEDICAL_AND_HEALTH_FACILITIES")} 
                    </li>
                    <li>
                    {i18n.t("WATER_INFRASTRUCTURE_DAMAGE")} 
                    </li>
                    <li>
                    {i18n.t("EDUCATIONAL_FACILITIES_DAMAGE")} 
                    </li>
                    <li>
                    {i18n.t("HUMANITARIAN_AID_RESOURCES_SEI")} 
                    </li>
                    <li>
                    {i18n.t("COMMERCIAL_FACILITIES_DAMAGE")} 
                    </li>
                    <li>
                    {i18n.t("GOVERNMENT_BUILDINGS_DAMAGE")} 
                    </li>
                    <li>
                    {i18n.t("MILITARY_FACILITIES_AND_ASSETS")} 
                    </li>
                    <li>
                    {i18n.t("INFRASTRUCTURE_DAMAGE")} 
                    </li>
                    <li>
                      {i18n.t("OTHER")}
                    </li>
                  </ul>
                </div>
                <div>
                  <p className={styles.underlinedText}>{i18n.t("VISIBLE_PERPETRATORS")}</p>
                  <ul className={styles.list}>
                    <li>
                      {i18n.t("RSF")}
                    </li>
                    <li>
                      {i18n.t("SAF")}
                    </li>
                    <li>
                    {i18n.t("ARMED_SELF_DEFENSE_GROUPS")} 
                    </li>
                    <li>
                    {i18n.t("OTHER_PERPETRATORS_CAN_BE_IDE")} 
                    </li>
                    <li>
                    {i18n.t("UNKNOWN_PERPETRATORS_CANNOT_B")} 
                    </li>
                    <li>
                    {i18n.t("EXTERNAL_FORCES_NON_SUDANESE")} 
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header={i18n.t("WARNINGS_HEADER")} key="5">
              <div className={styles.collapseContent}>
                <ul className={styles.list}>
                  <li>
                    {i18n.t("THE_MAP_AND_UNDERLYING_DATABAS")}
                  </li>
                  <li>
                    {i18n.t("EACH_PIECE_OF_DATA_HAS_BEEN_MA")}
                  </li>
                  <li>
                    {i18n.t("THE_SUDAN_SHAHID_TEAM_DOES_NOT")}
                  </li>
                  <li>
                    {i18n.t("THE_SUDAN_SHAHID_TEAM_ONLY_USE")}
                  </li>
                  <li>
                    {i18n.t("CIR_AS_ONE_DATA_CONTRIBUTOR_T")}
                  </li>
                </ul>
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </div>);

};