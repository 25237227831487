import { AnyAction } from "redux";
import { main } from "../constants/actionTypes";

interface InitialStateInterface {
  accessToken: string }


const initialState: InitialStateInterface = {
  accessToken: ""
};

const mainReducer = (state: InitialStateInterface = initialState, action: AnyAction): InitialStateInterface => {
  const { type, payload } = action;

  switch (type) {
    case main.SET_USER_DATA:
      return {
        ...state,
        accessToken: payload.accessToken
      };

    case main.SET_USER_PROFILE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default mainReducer;