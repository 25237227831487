import React, { CSSProperties } from "react";
import cn from "classnames";
import { EventDate } from "../../../../utils/tools";
import styles from "../events.module.css";

interface EventItemProps {
  date: EventDate,
  style: CSSProperties,
  onSelectEvent: () => void,
  description: string, }


export const EventItem = ({ date, style, onSelectEvent, description }: EventItemProps) => {
  return (
    <div className={styles.itemWrapper} style={style} onClick={onSelectEvent}>
      <div className={cn(styles.detailOfHistoryItem, styles.underline)}>
        <p title={description} className={styles.historyItemTitle}>
          {description}
        </p>
        <time className={styles.historyItemDate} dateTime={date.attrDateTime}>
          {date.textDateTime}
        </time>
      </div>
    </div>);

};