import { useEffect, useState } from "react";

type State<T> = [T, (val: T, callback?: () => void) => void];
type simpleFunc = () => void;

export const useStateWithCallback = <T>(value: T): State<T> => {
  const [val, setVal] = useState <T>(value);
  const [callbackFunc, setCallbackFunc] = useState <simpleFunc | null>(null);

  useEffect(() => {
    if (!callbackFunc) {
      return;
    }
    callbackFunc();
  }, [val, callbackFunc]);

  return [
  val,
  (val, callback) => {
    if (callback) {
      setCallbackFunc(callback);
    }
    setVal(val);
  }];

};