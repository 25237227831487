import i18n from 'i18next';import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Tooltip } from "antd";
import { format } from "date-fns";
import html2canvas from "html2canvas";
import { render } from "react-dom";
// @ts-ignore
import * as pdfMake from "pdfmake/build/pdfmake";
import ShareIcon from "../../../../images/share-icon.svg";
import { RootState } from "../../../../store";
import { Loading } from "../../../../components/loading";
import { cleanExportingImage, generateMapImage } from "../../reducerExtensions/keplerReduxExtension";
import { ActiveListItem } from "../../../../components/activeList";
import keplerStyles from "../../keplergl.module.css";
import styles from "./exportPdf.module.css";
import { FiltersData } from "../../../../interfaces/filters";

interface PdfContent {
  image: string,
  width?: number,
  pageOrientation?: "portrait" | "landscape",
  pageBreak?: "before" | "after", }


interface PdfConfigInterface {
  pageOrientation: "portrait" | "landscape",
  pageMargins: [number, number, number, number],
  content: PdfContent[], }


interface GenerateDocumentInterface {
  filters: FiltersData,
  mapImage: string,
  readyCallback: (mapImage: HTMLDivElement, filters: HTMLDivElement) => void, }


const PDF_DOC_SIZE = { width: 595, height: 842 };

const generatePdfDocument = async (mapImage: HTMLElement, filters: HTMLElement, remove: (() => void)) => {
  const mapImageCanvas = await html2canvas(mapImage);
  const filtersImageCanvas = await html2canvas(filters);

  const mapImgUri = mapImageCanvas.toDataURL("image/png", 1.0);
  const filterImgUri = filtersImageCanvas.toDataURL("image/png", 1.0);

  const pdfConfig: PdfConfigInterface = {
    pageOrientation: "landscape",
    pageMargins: [0, 0, 0, 0],
    content: [
    {
      image: mapImgUri,
      width: PDF_DOC_SIZE.height,
      pageOrientation: "landscape"
    }]

  };

  if (filters.outerText.length > 0) {
    pdfConfig.content = pdfConfig.content.concat([
    {
      image: filterImgUri,
      width: PDF_DOC_SIZE.width,
      pageOrientation: "portrait",
      pageBreak: "before"
    }]
    );
  }

  const doc = pdfMake.createPdf(pdfConfig);

  remove();

  doc.download(`SudanShahidMap${format(new Date(), "y LLLL d H_m_ss")}.pdf`);
};

export const ExportPdf: React.FC<ActiveListItem> = () => {
  const [loading, setLoading] = useState<(boolean)>((false));
  const keplerSelector: any = useSelector((state: RootState) => state.keplerGl);
  const filters = useSelector((state: RootState) => state.searchEngine.searchEngine.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    const exportImage = keplerSelector?.map?.uiState?.exportImage;
    if (!exportImage || exportImage.imageDataUri === "") {
      return;
    }
    const el = document.createElement("div");
    el.className = styles.pdfDocumentContainer;

    const componentDidMount = (mapImage: HTMLDivElement, filters: HTMLDivElement) => {
      document.body.append(el);
      generatePdfDocument(mapImage, filters, () => {
        el.remove();
      }).then(() => {
        cleanExportingImage()(dispatch);

        setLoading(false);
      });
    };
    render(
      <GenerateDocument readyCallback={componentDidMount} filters={filters} mapImage={exportImage.imageDataUri} />,
      el
    );
  }, [keplerSelector, filters]);

  const onClick = () => {
    setLoading(true);
    const { width } = keplerSelector.map.mapState;
    generateMapImage(width, width / 1.64)(dispatch);
  };

  return (
    <>
      <Modal className={styles.modal} open={loading} title={null} closeIcon={null} footer={null}>
        <div className={styles.modalContent}>
          <Loading />
          <span>{i18n.t("GENERATING_DOWNLOADABLE_PDF_DO")}</span>
        </div>
      </Modal>
      <Tooltip placement="left" title={i18n.t("DOWNLOAD_PDF")}>
        <div onClick={onClick} className={keplerStyles.expandContainer}>
          <img className={keplerStyles.expandBtn} src={ShareIcon} alt="Download pdf" />
        </div>
      </Tooltip>
    </>);

};

const GenerateDocument = ({ filters, mapImage, readyCallback }: GenerateDocumentInterface) => {
  const mapImageRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapImageRef?.current || !filterRef?.current) {
      return;
    }
    readyCallback(mapImageRef.current, filterRef.current);
  }, []);

  const displayList = (arr: string[], header: string) => {
    return (
      <>
        {arr.length !== 0 &&
        <div className={styles.listContainer}>
            <p className={styles.listHeader}>{header}</p>
            <ul className={styles.listStyle}>
              {arr.map((item) =>
            <li className={styles.listItem}>{item}</li>
            )}
            </ul>
          </div>}

      </>);

  };

  const displayEventsFilters = () => {
    const totalLength = filters.categories.length + filters.actor.length + filters.source.length;
    return (
      <div>
        <p className={styles.filtersContainerHeader}>{totalLength !== 0}</p>
        <div className={styles.filtersContainer}>
          {displayList(filters.categories, "Categories")}
          {displayList(filters.source, "Source")}
          {displayList(filters.actor, "Actor")}
        </div>
      </div>);

  };

  const prepareChosenOption = (chosenOption: string): string => {
    const items = chosenOption.split(",").filter((item) => item !== "null");
    return items.join(", ");
  };

  return (
    <div className={styles.pdfContainer}>
      <div className={styles.pdfMap} ref={mapImageRef}>
        <img className={styles.mapImage} src={mapImage} alt="Map Image" />
      </div>

      <div className={styles.filters} ref={filterRef}>
        {prepareChosenOption(filters.chosenOption).length !== 0 &&
        <div>
            <p className={styles.searchQueryTitle}>{i18n.t("SEARCH_QUERY")}</p>
            <p className={styles.searchQuery}>{prepareChosenOption(filters.chosenOption)}</p>
          </div>}

        <div className={styles.selectedFilters}>{displayEventsFilters()}</div>
      </div>
    </div>);

};