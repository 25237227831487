import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../header";
import style from "./layout.module.css";

export const Layout = () => {
  return (
    <div className={style.layout}>
      <Header />
      <Outlet />
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      {/*<nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/pro3f2ile">no found</Link>
            </li>
          </ul>
         </nav>*/}

      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
    </div>);

};