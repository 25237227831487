import { useEffect, useState } from "react";

export const useEffectWithMemory = <T>(
callback: ((save: (data: T) => void, getLast: () => T | null, softGetLast: () => T | null) => void),
dependencyArray: any[]) =>
{
  const [data, setData] = useState<T | null>(null);

  const save = (newData: T) => {
    if (data !== null) {
      return;
    }
    setData(newData);
  };

  const getLast = (): T | null => {
    setData(null);
    return data;
  };

  const softGetLast = (): T | null => {
    return data;
  };

  useEffect(() => {
    callback(save, getLast, softGetLast);
  }, dependencyArray);
};