export const home = {
  GET_DATA: "HOME_GET_DATA",
  GET_DATA_RECEIVED: "HOME_GET_DATA_RECEIVED"
};

export const main = {
  SET_USERS_ACCOUNTS: "MAIN_SET_USERS_ACCOUNTS",
  SET_USER_DATA: "MAIN_SET_USER_DATA",
  SET_USER_PROFILE: "MAIN_SET_USER_PROFILE"
};

export const searchEngineActions = {
  BIND_DATA_ACTION: "BIND_SEARCH_DATA_ACTION",
  SET_FILTERS_ACTION: "SET_SEARCH_FILTERS_ACTION",
  SET_LOADING_ACTION: "SET_LOADING_ACTION",
  CHANGE_INITIAL_STATE: "CHANGE_INITIAL_STATE",
  CHANGE_TYPE_OF_DATABASE: "CHANGE_TYPE_OF_DATABASE"
};

export const event = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL"
};

export const keplerExtension = {
  LOAD_POINTS_FILE: "LOAD_POINTS_FILE",
  UPDATE_KEPLER_MAP_STATE: "UPDATE_KEPLER_MAP_STATE",
  SOFT_UPDATE_KEPLER_ZOOM_STATE: "SOFT_UPDATE_KEPLER_ZOOM_STATE",
  UPDATE_KEPLER_GL: "UPDATE_KEPLER_GL",
  ENABLE_HEAT_MAP_MODE: "ENABLE_HEAT_MAP_MODE",
  SET_HEAT_MAP_CONFIG: "SET_HEAT_MAP_CONFIG",
  ENABLE_GEO_JSON_MODE: "ENABLE_GEO_JSON_MODE",
  SET_GEO_JSON_CONFIG: "SET_GEO_JSON_CONFIG",
  ENABLE_POINT_MODE: "ENABLE_POINT_MODE",
  SET_POINT_CONFIG: "SET_POINT_CONFIG",
  SET_COLOR_RANGE: "SET_COLOR_RANGE",
  SET_LAYER_VIS_CONFIG: "SET_LAYER_VIS_CONFIG",
  SET_LAYER_CONFIG: "SET_LAYER_CONFIG"
};

export const editorModeActions = {
  SET_EDITOR_MODE: "SET_EDITOR_MODE",
  SET_MIN_MAX_BOX: "SET_MIN_MAX_BOX",
  SET_FEATURE_FILTER: "SET_FEATURE_FILTER"
};

export const taskReducerActions = {
  CLEAR: "CLEAR"
};