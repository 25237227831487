export default {
  "BACK": `Back`,
  "VAR_GREY": `var(--grey-700)`,
  "PX_SOLID_RGBA": `1px solid rgba(255, 255, 255, 0.3)`,
  "JACK": `Jack`,
  "LUCY": `Lucy`,
  "DISABLED": `Disabled`,
  "YIMINGHE": `yiminghe`,
  "NOTHING_TO_SEE_HERE": `Nothing to see here!`,
  "RETURN_HOME": `Return home`,
  "ABOUT": `About`,
  "IN_RESPONSE_TO_AN_OUTBREAK_OF": `In response to an outbreak of armed conflict between Sudan's Rapid Support Forces and the Sudanese Armed Forces, in early April of 2023, C4ADS launched an effort to collect, monitor, and analyze visual documentation of surging violence across Sudan.`,
  "C_ADS_IS_A_C_NONPROFIT_O": `C4ADS is a 501c(3) nonprofit organization in Washington, DC with a mission to defeat the illicit networks that threaten global peace and security. Powered by cutting-edge data science, innovative technology applications, and evidence-driven analysis, C4ADS works to coordinate effective global responses to drivers of conflict, instability, environmental crime, and human rights abuses around the world.`,
  "FOLLOWING_THE_ESCALATION_OF_VI": `Following the escalation of violence in Sudan, C4ADS coordinated with the UK-based C4ADS coordinated with the Centre for Information Resilience (CIR) and the Sudan Human Rights Hub (HRH) to set up a sustainable reporting pipeline to document, structure, and verify photos and videos captured on the ground. C4ADS built the Sudan Shahid map to unify this live database with other conflict-tracking maps and databases from across the larger C4ADS partner network.`,
  "THE_MAP_ALSO_FEATURES_CONFLICT": `The map also features conflict events curated the Armed Conflict Location & Event Data Project (`,
  "ACLED": `ACLED`,
  "_": `).`,  
  "WE_ARE_GRATEFUL_TO_ACLED_FOR_P": `We are grateful to ACLED for permitting us to include ACLED's collection of political violence and protest events in the Sudan Shahid map visualization.`,
  "PARTNER_ORGANIZATIONS": `Partner Organizations`,
  "THE_CENTRE_FOR_INFORMATION_RES": `The Centre for Information Resilience (CIR) is an independent, non-profit social enterprise dedicated to exposing human rights abuses and war crimes and countering disinformation.`,
  "THE_SUDAN_HUMAN_RIGHTS_HUB_HR": `The Sudan Human Rights Hub (HRH) is a coordination and resource center empowering grassroots human rights groups to archive documentation, and use data for accountability and advocacy.`,
  "SUDAN_SHAHID_CONCEPTUALLY_BUIL": `Sudan Shahid conceptually builds on HRH's Sudan Protest Monitor project as well as previous C4ADS-CIR collaborations: the`,
  "EYES_ON_RUSSIA_MAP": `Eyes on Russia Map`,
  "AND": `and`,
  "AFGHAN_WITNESS_MAP": `Afghan Witness Map`,
  "_1": `.`,
  "THIS_PLATFORM_IS_NAMED_SUDAN_S": `This platform is named Sudan Shahid (pronounced: shaa-hid, the Arabic word for witness) in honor of the incredible partners on the ground in Sudan and around the world, who have filmed, documented, and shared documentation of the recent violence in order to bear witness and increase awareness in the international community. We are grateful to the partners who have supported the collection of this valuable information, namely:`,
  "ARTRIKA_DATABASE_IS_AN_ONLINE": `Artrika Database is an online data storage project to preserve data and information of the people who were displaced from their homeland to IDP camps by the Sudanese government. The database uses research and oral history as tools for data collection;`,
  "AND_MANY_OTHERS_WHO_HAVE_CHOSE": `And many others who have chosen to remain anonymous.`,
  "WE_ARE_EAGER_TO_WELCOME_IN_NEW": `We are eager to welcome in new partners to Sudan Shahid, and can provide training and capacity-building in order to support further documentation of this conflict around the world. Interested partners should reach out to`,
  "INFO_SUDANSHAHID_ORG": `info@sudanshahid.org.`,
  "ABOUT_THE_DATA": `About the Data`,
  "SUDAN_SHAHID_DRAWS_ON_A_UNIFIE": `Sudan Shahid draws on a unified database of videos, photos, satellite imagery or other media related to the 2023 conflict in Sudan that members of the Sudan Shahid team have been collecting and verifying since April 15, 2023. The map showcases data from three sources:`,
  "CIR_OPEN_SOURCE_MEDIA_INCLUD": `CIR: Open-source media (including photos and videos) verified by CIR.`,
  "C_ADS_PARTNER_NETWORK_OPEN_A": `C4ADS Partner Network: open- and closed-source media (including photos and videos), which have been provided by C4ADS partner organizations located in Sudan.`,
  "SATELLITE_DATA_PROVIDED_OR_CA": `Satellite: Data provided or captured by satellites, showing conflict events that may not be documented using on-the-ground media sources, which is made available on open source platforms to increase public awareness.`,
  "YOU_CAN_SELECT_THE_DATA_TO_BE": `You can select the data to be shown on the map in four ways:`,
  "SELECT_ONE_OR_MORE_SOURCES_OF": `Select one or more Sources of data`,
  "SELECT_ONE_OR_MORE_ACTORS_ALLE": `Select one or more Actors allegedly perpetrating the event`,
  "SELECT_START_AND_END_DATES_FOR": `Select start and end dates for the time period being researched`,
  "SEARCH_FOR_KEY_WORDS_SUCH_AS_P": `Search for key words such as place names using the free text box`,
  "THESE_SEARCH_FUNCTIONS_CAN_BE": `These search functions can be applied together or on their own. Descriptions of the Categories are provided below.`,
  "THE_MAP_WILL_PRESENT_DATA_MATC": `The map will present data matching your search and filter parameters. To continue your analysis of the data:`,
  "HOVER_YOUR_CURSOR_OVER_A_PIN_T": `Hover your cursor over a pin to display a brief description of the data's characteristics`,
  "CLICK_ON_A_PIN_TO_DISPLAY_A_PR": `Click on a pin to display a preview box of the data and its characteristics, including a link to the original source of the data`,
  "MOVE_THE_BRACKETS_ALONG_THE_TI": `Move the brackets along the timeline function at the bottom of the screen and press the “play” button to view a time lapse of the emergence of the pins over the time period being researched`,
  "INCREASE_OR_DECREASE_THE_MAGNI": `Increase or decrease the magnification of the map and search for only events within the map frame`,
  "CATEGORY_HARM_TO_PERSONS": `Category – Harm to Persons`,
  "ARREST_DETENTION_OR_ABDUCTION": `Arrest, Detention or Abduction`,
  "CIVILIAN_INJURY": `Civilian injury`,
  "CIVILIAN_DEATH": `Civilian death`,
  "HUMILIATION_OF_CIVILIANS": `Humiliation of civilians`,
  "FORCED_RECRUITMENT_OR_CONSCRIP": `Forced recruitment or conscription of civilians`,
  "CIVILIAN_PROPERTY_LOOTING_OR_O": `Civilian property looting or occupation`,
  "DISPLACEMENT_OF_CIVILIANS": `Displacement of civilians`,
  "INTERROGATION_OR_TORTURE_OF_CI": `Interrogation or torture of civilians`,
  "MASS_GRAVES_AND_BURIAL_SITES": `Mass graves and burial sites`,
  "UNEXPLODED_ORDINANCE": `Unexploded ordinance`,
  "HARM_TO_COMBATANTS": `Harm to combatants`,
  "CATEGORY_FORMS_OF_VIOLENCE": `Category – Forms of Violence`,
  "GUNFIRE": `Gunfire`,
  "BOMBING_OR_EXPLOSION": `Bombing or explosion`,
  "FIRE": `Fire`,
  "PHYSICAL_VIOLENCE": `Physical violence`,
  "AIRSTRIKES": `Airstrikes`,
  "CATEGORY_MILITARY_ACTIVITIES": `Category – Military Activities`,
  "INCITEMENT_AND_HATE_SPEECH": `Incitement and hate-speech`,
  "FIRING_POSITIONS": `Firing positions`,
  "ROADBLOCKS_OR_RESTRICTIONS_ON": `Roadblocks or restrictions on physical movement`,
  "LOCATIONS_AND_MOVEMENTS": `Locations and movements`,
  "FORMS_OF_EQUIPMENT_OR_MUNITION": `Forms of equipment or munitions`,
  "CAPTURE_OF_OPPOSING_COMBATANTS": `Capture of opposing combatants`,
  "CATEGORY_DAMAGE_TO_PROPERTY": `Category – Damage to Property or Infrastructure`,
  "CIVILIAN_PROPERTY_DAMAGE": `Civilian property damage`,
  "MEDICAL_AND_HEALTH_FACILITIES": `Medical and health facilities damage`,
  "WATER_INFRASTRUCTURE_DAMAGE": `Water infrastructure damage`,
  "EDUCATIONAL_FACILITIES_DAMAGE": `Educational facilities damage`,
  "HUMANITARIAN_AID_RESOURCES_SEI": `Humanitarian aid resources seizure or damage`,
  "COMMERCIAL_FACILITIES_DAMAGE": `Commercial facilities damage`,
  "GOVERNMENT_BUILDINGS_DAMAGE": `Government buildings damage`,
  "MILITARY_FACILITIES_AND_ASSETS": `Military facilities and assets damage`,
  "INFRASTRUCTURE_DAMAGE": `Infrastructure damage`,
  "OTHER": `Other`,
  "VISIBLE_PERPETRATORS": `Visible Perpetrators`,
  "RSF": `RSF`,
  "SAF": `SAF`,
  "ARMED_SELF_DEFENSE_GROUPS": `Armed self defense groups`,
  "OTHER_PERPETRATORS_CAN_BE_IDE": `Other (perpetrators can be identified)`,
  "UNKNOWN_PERPETRATORS_CANNOT_B": `Unknown (perpetrators cannot be identified)`,
  "EXTERNAL_FORCES_NON_SUDANESE": `External forces (non-Sudanese forces)`,
  "THE_MAP_AND_UNDERLYING_DATABAS": `The map and underlying database only contains information collected by the Sudan Shahid network. The database is not comprehensive of all events nor all information about those events.`,
  "EACH_PIECE_OF_DATA_HAS_BEEN_MA": `Each piece of data has been marked with a violence level to indicate the level of graphic content; highly graphic content is not included in preview boxes.`,
  "THE_SUDAN_SHAHID_TEAM_DOES_NOT": `The Sudan Shahid team does not publish information that might put any individuals at risk. On occasion we may delay publication to ensure the safety of civilians in Sudan.`,
  "THE_SUDAN_SHAHID_TEAM_ONLY_USE": `The Sudan Shahid team only uses data collected and reviewed by our partners; we are not responsible for and do not endorse claims about events made on social media.`,
  "CIR_AS_ONE_DATA_CONTRIBUTOR_T": `CIR, as one data contributor to the Sudan Shahid project, runs open source data through a rigorous verification process which involves several stages of review. However, if you believe any CIR data to be inaccurate please email osint@info-res.org explaining why you think the data is not correct and CIR will review that data.`,
  "ENGLISH": `English`,
  "ARABIC": `العربية`,
  "_3": `/`,
  "ABOUT1": `/about`,
  "DD_MM_YYYY": `DD/MM/YYYY`,
  "SELECT": `Select`,
  "RECTANGLE": `Rectangle`,
  "POLYGON": `Polygon`,
  "HIDE": `Hide`,
  "SHOW": `Show`,
  "GENERATING_DOWNLOADABLE_PDF_DO": `Generating downloadable PDF document...`,
  "SEARCH_QUERY": `Search Query:`,
  "THE_SUDAN_SHAHID_MAP": `The Sudan Shahid Map`,
  "BY_C_ADS": `By C4ADS`,
  "SUDAN_SHAHID_IS_A_GLOBAL_COLLA": `Sudan Shahid is a global collaborative effort tocollect, monitor, and analyze visual documentation of surging violence across Sudan.`,
  "IN_RESPONSE_TO_AN_OUTBREAK_OF1": `In response to an outbreak of violence between Sudan's warring armed forces, C4ADS coordinated with the UK-based Centre for Information Resilience (CIR) and Sudan's Human Rights Hub (HRH) to establish a support structure for conflict monitoring. The effort produced a reporting mechanism and data center customized to the needs of on-the-ground partner networks documenting the conflict. Built to bear witness to the ongoing violence in Sudan and open the door to international accountability, Sudan Shahid serves as an interactive database of the verified photos and videos collected through this pipeline, along with satellite imagery and other media collected by CIR, HRH, and the larger C4ADS partner network. The map also features conflict events curated by the Armed Conflict Location & Event Data Project (`,
  "MORE_INFORMATION_ABOUT_THE_PRO": `More information about the project and guidance on how to use the map is available`,
  "AT_THE_ABOUT_PAGE": `at the About page.`,
  "GRAPHIC_CONTENT_LEVEL": `Graphic Content Level:`,
  "DESCRIPTION": `Description:`,
  "ACTOR_DETAILS": `Actor details:`,
  "HEATMAP": `heatmap`,
  "GEOJSON": `geojson`,
  "BASEMAP": `Basemap`,
  "DEFAULT": `Default`,
  "CLASSIC": `Classic`,
  "EARTH": `Earth`,
  "CATEGORIES": `Categories`,
  "MAP": "Map",
  "ABOUT": "About",
  "ACTOR": "Actor",
  "DATA_SOURCE": "Data Source",
  "START_DATE": "Start Date",
  "END_DATE": "End Date",
  "ONLY_EVENTS_IN_MAP_FRAME": "Only events in map frame",
  "RESET": "Reset",
  "NO_EVENTS_FOUND": "No events found.",
  "LOADING": "Loading",
  "NO_EVENTS_FOUND_REFRESH_PLEASE": "If you think this is in error, please try refreshing the page",
  "SEARCH_LOC_OR_KEYWORDS": "Search locations or keywords",
  "TIMELINE":"Timeline",
  "SUDAN_SHAHID_MAP": "Sudan Shahid Map",
  "EVENTS": "Events",
  "ABOUT_THE_PROJECT_HEADER": "About the Sudan Shahid Project",
  "HOW_TO_USE_MAP_HEADER": "How to Use the Map",
  "DEFINITIONS_HEADER":"Definitions",
  "WARNINGS_HEADER":"Warnings and Disclaimers",
  "SELECT_ALL": "Select All",
  "REMOVE_ALL": "Remove All",
  "APPLY": "Apply",
  "DRAW_ON_MAP": "Draw on map",
  "INFO": "Info",
  "DOWNLOAD_PDF": "Download PDF",
  "LEGEND": "Legend",
  "KEYWORD_SEARCH_FOR": "Keyword search for ",
  "ENTRY": "Entry",
  "ENTRY_NUM": "Entry Number",
  "STATE": "State",
  "CITY": "City",
  "DATA_SOURCE": "Data Source",
  "COORDINATES": "Coordinates",
  "LINK": "Link",
  "HIDDEN": "Hidden",
  "WHY_SUDAN_SHAHID": "Why Sudan Shahid?",
  "THIS_PLATFORM_IS_NAMED": "This platform is named Sudan Shahid (pronounced: shaa-hid, the Arabic word for witness) in honor of the incredible partners on the ground in Sudan and around the world who have filmed, documented, and shared documentation of the recent violence in order to bear witness and increase awareness in the international community.",
  "WE_ARE_GRATEFUL": "We are grateful to the partners who have supported the collection of this valuable information. Without their bravery, the world would be unaware of the full extent of the violence affecting the people of Sudan.",
  "EXPLORE_THE_SUDAN_SHAHID_MAP": "EXPLORE THE SUDAN SHAHID MAP",
};
