import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import { processGeojson } from "kepler.gl/processors";
import { Button } from "antd";
import cn from "classnames";
import styles from "./home.module.css";
import { DateRange, FiltersData } from "../../interfaces/filters";
import { Search } from "./search";
import { Kepler } from "../../pages/keplergl";
import SideBarArrow from "../../images/side-bar-arrow.svg";
import MapIcon from "../../images/map-icon.svg";
import CloseImage from "../../images/close-icon-black.svg";
import { createBindDataAction, setFilterActionCreator } from "./search/reducer";
import { FullScreenLoading } from "../../components/loading";
import { createOpenModalEventAction } from "./event/reducer";
import { EventModal } from "./event";
import { getKeplerSelector, loadEventsData, LoadingCallbackArgument } from "../../utils/tools";
import { SubmitEvent } from "./submitEvent";
import { SubmitFormFields } from "./submitEvent/submitEvent";
import { RootState } from "../../store";
import { AdaptiveModal } from "../../components/adaptiveModal";
import { Layout } from "../../components/layout";
import { About } from "../about";
import i18n from 'i18next';

interface DataLoadingState {
  loading: boolean;
  message: string;
}

const getResource = async (): Promise<string> => {
  const resourceUrl = process.env.REACT_APP_RESOURCE_URL;

  if (resourceUrl === undefined) {
    throw new Error("REACT_APP_RESOURCE_URL must be set");
  }

  return resourceUrl;
};

const labelColors: Record<string, string> = {
  Default: "#344054",
  Classic: "#344054",
  Earth: "#FFFFFF",
};

export const Home = () => {
  const [loading, setLoading] = useState<DataLoadingState>({
    loading: true,
    message: "",
  });
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [filters, setFilters] = useState<FiltersData>();
  const event = useSelector((state: RootState) => state.event);
  const { initialStateChanged } = useSelector((state: RootState) => state.searchEngine);
  const { currentMap } = useSelector((state: RootState) => state.keplerMapState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (event?.event != null) {
      navigate(`/event/${event.event.uniqueIndex}${window.location.search}`);
    }
  }, [event]);

  useEffect(() => {
    const loadFunction = (url: string) => {
      loadEventsData(url, "events_cache_name", (message: LoadingCallbackArgument) => {
        setLoading((prevDataLoading) => ({ ...prevDataLoading, message: message.message }));
      })
        .then((data: any) => {
          dispatch(createBindDataAction(processGeojson(data)));
          setLoading((prevDataLoading) => ({ ...prevDataLoading, loading: false }));
        })
        .catch((err) => {
          console.log("loadEventsData function error\n" + err);
        });
    };

    getResource().then((url) => {
      loadFunction(url);
    });
  }, [setLoading, dispatch]);

  useEffect(() => {
    if (!filters || loading.loading) {
      return;
    }

    const dateRange = filters.dateRange !== null ? filters.dateRange : getDefaultDateRange();

    dispatch(
      setFilterActionCreator({
        ...filters,
        dateRange: dateRange,
      })
    );
  }, [loading, filters, initialStateChanged]);

  const onSelectEvent = (eventObj: any) => {
    dispatch(
      createOpenModalEventAction({
        event: eventObj,
        ignoreZoom: true,
      })
    );
  };

  const getDefaultDateRange = (): DateRange => {
    const keplerSelector = getKeplerSelector();

    if (!keplerSelector?.map?.visState?.filters?.length || keplerSelector?.map?.visState?.filters?.length === 0) {
      return [new Date(), new Date()];
    }
    const defaultDateRange = keplerSelector.map.visState.filters[0].domain;
    if (defaultDateRange.length === 0) {
      return null;
    }

    const [startDate, endDate] = defaultDateRange;
    return [new Date(startDate), new Date(endDate)];
  };

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const filtersOnChange = (newFilters: FiltersData) => {
    setFilters(newFilters);
  };

  return (
    <>
      {loading.loading ? (
        <FullScreenLoading>
          <span>{loading.message}</span>
        </FullScreenLoading>
      ) : (
        <AboutMapPopup />
      )}

      <div className={cn(styles.dashboard, sideBarOpen && styles.dashboardSideBarOpen)}>
        <div className={styles.content}>
          <div className={cn(styles.filtersContainer, !sideBarOpen && styles.filtersContainerClose)}>
            <Search onFilters={filtersOnChange} onSelectEvent={onSelectEvent} />
            <button
              onClick={toggleSideBar}
              className={cn(styles.toggleSideBarBtn, sideBarOpen && styles.toggleSideBarBtnOpen)}
            >
              <img src={SideBarArrow} className={cn(!sideBarOpen && styles.sideBarImageClose)} alt="Toggle side bar" />
            </button>
          </div>
          <p
            style={{ color: labelColors[currentMap.name] }}
            className={cn(styles.mapLabel, !sideBarOpen && styles.mapLabelFull)}
          >
            {i18n.t("SUDAN_SHAHID_MAP")}
          </p>
          <div className={cn(styles.keplerMapContainer, sideBarOpen ? styles.keplerMin : styles.keplerFull)}>
            <Kepler />
          </div>
        </div>
      </div>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/about" element={<About />} />
        </Route>
        <Route
          path="event/:id"
          element={
            <div className={styles.eventModal}>
              <EventModal />
            </div>
          }
        />
      </Routes>
    </>
  );
};

const modalLocalStorageKey = "info-modal";

const AboutMapPopup = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const opened = localStorage.getItem(modalLocalStorageKey);
    if (!opened) {
      setOpen(true);
      localStorage.setItem(modalLocalStorageKey, "true");
    }
  }, []);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <AdaptiveModal
        open={open}
        close={close}
        children={
          <div className={styles.aboutPopup}>
            <div className={styles.aboutPopupHeader}>
              <div className={styles.mapIconBlock}>
                <img src={MapIcon} alt="Map icon" className={styles.mapIcon} />
              </div>
              <div className={styles.aboutPopupHeaderTitle}>
                <h1 className={styles.aboutPopupTitle}>{i18n.t("THE_SUDAN_SHAHID_MAP")}</h1>
                <p className={styles.aboutPopupTitleSub}>{i18n.t("BY_C_ADS")}</p>
              </div>
            </div>
            <hr className={styles.divideLine} />
            <div className={styles.aboutPopupContent}>
              <p>
              {i18n.t("SUDAN_SHAHID_IS_A_GLOBAL_COLLA")}
              </p>
              <p>
              {i18n.t("IN_RESPONSE_TO_AN_OUTBREAK_OF1")}<a href="https://acleddata.com/about-acled/" target="_blank">
                  {i18n.t("ACLED")}
                </a>{i18n.t("_")}
              </p>
              <p>
              {i18n.t("MORE_INFORMATION_ABOUT_THE_PRO")}
                <a href="/about" target="_blank">
                  {" "}
                  {i18n.t("AT_THE_ABOUT_PAGE")}
                </a>
              </p>
              <h3 style={{color: "#fff"}}>{i18n.t("WHY_SUDAN_SHAHID")}</h3>
              <p>
              {i18n.t("THIS_PLATFORM_IS_NAMED")}
              </p>
              <p>
              {i18n.t("WE_ARE_GRATEFUL")}
              </p>
            </div>
            <div className={styles.aboutPopupFooter}>
              <span className={styles.footerInfoText}>
                <a className={styles.footerLink} onClick={close}>
                {i18n.t("EXPLORE_THE_SUDAN_SHAHID_MAP")}
                </a>
              </span>
            </div>
          </div>
        }
      />
      <button onClick={close} className={cn(styles.closeAboutPopup, !open && styles.closeAboutPopupHidden)}>
        <img src={CloseImage} alt="Close about popup" />
      </button>
      {/* Here I move this button outside the adaptive modal block, in order to avoid the unwanted animation caused by antd design */}
    </>
  );
};

// I will leave the "Submit Event" code until the final confirmation that this functionality is not needed.
const SubmitEventContainer = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = (fields: SubmitFormFields) => {
    setOpen(false);
    console.log(fields);
  };

  return (
    <>
      <Button className={styles.submitButton} type="primary" onClick={() => setOpen(true)}>
        Submit Event
      </Button>
      <SubmitEvent onSubmit={onSubmit} open={open} onClose={() => setOpen(false)} />
    </>
  );
};
