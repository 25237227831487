import i18n from 'i18next';import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import cn from "classnames";
import InfoIcon from "../../../images/info-icon.svg";
import MinimizeIcon from "../../../images/minimize-icon.svg";
import { ActiveListItem } from "../../../components/activeList";
import styles from "../keplergl.module.css";

export const InfoPopup: React.FC<ActiveListItem> = ({ onActive, onInActive, active }: ActiveListItem) => {
  const [popupOpen, setPopupOpen] = useState<(boolean)>((active));
  const [tooltipOpen, setTooltipOpen] = useState<(boolean)>((false));

  useEffect(() => {
    setPopupOpen(active);
  }, [active]);

  const tooltipOpenChange = (open: boolean) => {
    if (popupOpen) {
      setTooltipOpen(false);
      return;
    }

    setTooltipOpen(open);
  };

  const openPopup = () => {
    onActive();
    setTooltipOpen(false);
    setPopupOpen(true);
  };

  const closePopup = () => {
    onInActive();
    setTooltipOpen(true);
    setPopupOpen(false);
  };

  return (
    <Tooltip placement="left" open={tooltipOpen} onOpenChange={tooltipOpenChange} title={i18n.t("INFO")}>
      <div className={styles.expandContainer}>
        <img
          className={cn(styles.expandBtn, popupOpen && styles.hideExpandBtn)}
          onClick={openPopup}
          src={InfoIcon}
          alt="Info" />

        {popupOpen &&
        <div className={styles.expandPopupContent}>
            <button onClick={closePopup} className={styles.minimizeBtn}>
              <img src={MinimizeIcon} alt="Minimize info popup" />
            </button>
            <h1 className={styles.aboutPopupTitle}>{i18n.t("THE_SUDAN_SHAHID_MAP")}</h1>
            <p className={styles.aboutPopupTitleSub}>{i18n.t("BY_C_ADS")}</p>
            <br />
            <p>
              {i18n.t("SUDAN_SHAHID_IS_A_GLOBAL_COLLA")} 
              </p>
              <p>
              {i18n.t("IN_RESPONSE_TO_AN_OUTBREAK_OF1")}<a href="https://acleddata.com/about-acled/" target="_blank">
                  {i18n.t("ACLED")}
                </a>{i18n.t("_")}
              </p>
              <p>
              {i18n.t("MORE_INFORMATION_ABOUT_THE_PRO")}
                <a href="/about" target="_blank">
                  {" "}
                  {i18n.t("AT_THE_ABOUT_PAGE")}
                </a>
              </p>
          </div>}

      </div>
    </Tooltip>);

};